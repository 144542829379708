<template>
  <div>
    <template v-if="loading && !$store.getters.error">
      <preloader></preloader>
    </template>
    <template v-else>
      <backButton />

      <v-layout row>
        <v-flex xs12>
          <v-form ref="form" lazy-validation>
            <h3 class="mb-1">{{ $t("Module name") }}</h3>

            <v-card class="mb-4">
              <v-card-text>
                <v-text-field
                  :label="$t('Name')"
                  v-model="website.Name"
                  required
                  :rules="[
                    (v) => !!v || $t('Name is required'),
                    (v) =>
                      v.length >= 2 ||
                      $t('Minimum required characters is') + ' 2',
                  ]"
                  validate-on-blur
                ></v-text-field>
              </v-card-text>
            </v-card>

            <h3 class="mb-1 mt-5">{{ $t("Domain names") }}</h3>

            <v-card class="mb-4">
              <v-card-text>
                <template v-for="(domain, index) in website.Domains">
                  <v-layout row wrap :key="index">
                    <v-flex xs9 sm11>
                      <v-text-field
                        label="Url"
                        v-model="website.Domains[index]"
                        required
                        :rules="[
                          (v) => !!v || $t('URL is required'),
                          (v) =>
                            /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/.test(
                              v
                            ) ||
                            $t(
                              'Url is not valid. The required format is http://domain.com'
                            ),
                        ]"
                        validate-on-blur
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs3 sm1>
                      <v-btn
                        icon
                        class="mt-4"
                        color="red"
                        v-on:click="removeDomain(index)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </template>

                <v-btn
                  color="green"
                  class="ml-0 edit-page-action-button"
                  dark
                  v-on:click="addDomain()"
                >
                  {{ $t("Add domain name") }}
                  <v-icon right>mdi-plus</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>

            <h3 class="mb-1 mt-5">{{ $t("Security") }}</h3>

            <v-card class="mb-4">
              <v-card-text>
                <v-switch
                  v-model="website.EnableApi"
                  light
                  v-bind:label="$t('Enable API')"
                ></v-switch>
              </v-card-text>
            </v-card>

            <h3 class="mb-1 mt-5">{{ $t("Home page") }}</h3>
            <v-card class="mb-4">
              <v-card-text>
                <v-select
                  v-model="website.DashboardPageId['$oid']"
                  item-text="name"
                  item-value="id"
                  :items="getHomePageNames"
                  dense
                  clearable
                  :no-data-text="$t('No data available')"
                >
                </v-select>
              </v-card-text>
            </v-card>

            <h3 class="mb-1 mt-5">{{ $t("Pages") }}</h3>
            <v-card>
              <v-card-text>
                <template v-for="(page, index) in website.Pages">
                  <pageNameLanguageSelector
                    :page="page"
                    :websiteId="website._id"
                    @removePage="removePage(index)"
                    @addWebsiteCopy="addWebsiteCopy"
                    :key="index"
                  />
                </template>

                <v-btn
                  color="green"
                  class="ml-0 edit-page-action-button"
                  dark
                  v-on:click="addPage()"
                >
                  {{ $t("Add page") }}
                  <v-icon right>mdi-plus</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>

            <cancelConfirmButtons
              @goBack="goBack"
              :confirmFunction="saveWebsite"
              :loading="saving"
              :confirmLabel="$t('Save')"
            />

          </v-form>
        </v-flex>
      </v-layout>
    </template>
  </div>
</template>

<script>
import request from "../../request";
import helpers from "../../helpers";

export default {
  data() {
    return {
      website: {
        Name: "",
        Domains: [""],
        DashboardPageId: { $oid: 0 },
        EnableApi: false,
        Pages: [
          {
            Name: helpers.createEmptyValueBasedOnType("text"),
            RepeatingContent: false,
            Enabled: false,
            CacheContent: false,
            _id: { $oid: 0 },
            Title: helpers.createEmptyValueBasedOnType("text"),
            Url: helpers.createEmptyValueBasedOnType("text"),
            UniqueName: "",
            SubPages: [],
          },
        ],
      },
      headers: [
        {
          text: this.$i18n.translate("Name"),
          value: "Name",
          align: "left",
          sortable: false,
        },
        {
          text: this.$i18n.translate("Enabled"),
          value: "Enabled",
          align: "left",
          sortable: false,
        },
        {
          text: this.$i18n.translate("Repeat content"),
          value: "RepeatingContent",
          align: "left",
          sortable: false,
        },
        { text: "", value: "", align: "left", sortable: false },
      ],
      loading: false,
      saving: false,
    };
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getHomePageNames() {
      let pages = [];

      this.website.Pages.forEach((item) => {
        if (item._id.$oid !== 0) {
          pages.push({
            id: item._id["$oid"],
            name:
              item.Name[this.currentLanguage] !== ""
                ? item.Name[this.currentLanguage]
                : item.Name["Nl"],
          });
        }
      });
      return pages;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getWebsite(this.$route.params.id);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getWebsite(id) {
      if (id) {
        this.loading = true;

        request.get("/website/" + id, null, (res) => {
          this.loading = false;

          if (res.success) {
            res.data.Pages = res.data.Pages.sort(
              helpers.sortByKey(this.currentLanguage)
            );
            this.website = res.data;
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      }
    },
    saveWebsite() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        const websiteToSave = JSON.parse(JSON.stringify(this.website));

        if (
          !websiteToSave["DashboardPageId"]["$oid"] ||
          websiteToSave["DashboardPageId"]["$oid"] == 0
        ) {
          delete websiteToSave["DashboardPageId"];
        }
        for (let i = 0; i < websiteToSave.Pages.length; i++) {
          if (websiteToSave.Pages[i]["_id"]["$oid"] == 0) {
            delete websiteToSave.Pages[i]._id;
          }
        }

        request.put("/website", websiteToSave, (res) => {
          this.saving = false;

          if (res.success) {
            this.$router.go(-1);
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      } else {
        this.$store.dispatch(
          "setError",
          this.$i18n.translate(
            "Please make sure all fields have a correct value"
          )
        );
      }
    },
    addDomain() {
      this.website.Domains.push("");
    },
    removeDomain(index) {
      this.website.Domains.splice(index, 1);
    },
    addWebsiteCopy(event) {
      request.post("/copy-page/" + event, null, (res) => {
        if (res.data && this.$route.params.id) {
          this.getWebsite(this.$route.params.id);
        }
      });
    },
    addPage() {
      this.website.Pages.push({
        Name: helpers.createEmptyValueBasedOnType("text"),
        RepeatingContent: false,
        Enabled: false,
        CacheContent: false,
        WebsiteId: this.website._id,
        _id: { $oid: 0 },
        Title: helpers.createEmptyValueBasedOnType("text"),
        Url: helpers.createEmptyValueBasedOnType("text"),
        UniqueName: "",
        SubPages: [],
      });
    },
    removePage(index) {
      if (
        this.website.Pages[index]._id.$oid ==
        this.website["DashboardPageId"]["$oid"]
      ) {
        this.$set(this.website["DashboardPageId"], "$oid", 0);
      }
      this.website.Pages.splice(index, 1);
    },
  },
};
</script>
