<template>
  <div>
    <backButton v-if="prevRoute != 'login'" />
    <v-row no-gutters>
      <v-col sm="12">
        <template v-if="page">
          <!-- Add page title -->
          <v-layout
            v-if="
              !page.RepeatingContent ||
              (page.RepeatingContent &&
                (editMode || Object.keys(page.ContentDefinition).length == 0))
            "
            row
          >
            <v-flex sm9>
              <h2 class="mb-3">{{ page.Name[currentLanguage] }}</h2>
            </v-flex>
            <v-flex sm3 class="text-right">
              <template v-if="userIsAdministrator && !page.Custom">
                <v-switch
                  class="float-right mt-0"
                  v-model="editMode"
                  light
                  v-bind:label="$t('Edit mode')"
                ></v-switch>
              </template>
            </v-flex>
          </v-layout>

          <!-- The page has only one item -->
          <template v-if="!page.RepeatingContent && page.Custom">
            <component :is="customComponent"></component>
          </template>
          <template
            v-else-if="
              !page.RepeatingContent &&
              content &&
              Object.keys(content).length > 0
            "
          >
            <v-form ref="form" id="editPageForm" lazy-validation>
              <v-row
                v-for="(items, index) in getContentData"
                :key="index"
                class="flex mt-0"
                dense
              >
                <template>
                  <pageElement
                    class="align-stretch"
                    v-for="(item, i) in items"
                    :key="i"
                    :item="item"
                    :editMode="userIsAdministrator && editMode"
                    :websiteId="page.WebsiteId.$oid"
                    :pageId="page._id.$oid"
                    :objectName="i"
                    :colsWidth="item.colWidth"
                    @removeItem="removeItem(i)"
                    @moveUpNonRepPage="moveUpNonRepPage"
                    @moveDownNonRepPage="moveDownNonRepPage"
                    ref="pageElement"
                  />
                </template>
              </v-row>
            </v-form>

            <template v-if="userIsAdministrator && editMode">
              <br />
              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Add item") }}</h3>
                  <addRemovePageElement
                    @addItem="addObjectToPageContent"
                    :enableAdd="true"
                  />
                </v-card-text>
              </v-card>
            </template>
          </template>
          <!-- The page has repeating objects -->
          <template v-else-if="page.RepeatingContent">
            <!-- The page has no content definition -->
            <errorHandeling
              v-if="
                Object.keys(page.ContentDefinition).length == 0 && !editMode
              "
              :snackbarText="$t('This page has not been configured yet')"
              :buttons="[
                {
                  isText: true,
                  functionName: 'clearError',
                  text: 'Close',
                },
              ]"
              snackbarColor="info"
              snackbarTimout="-1"
              snackbarIcon="mdi-information"
            />
            <!-- List repeating page items -->
            <template v-else-if="!editMode">
              <v-layout row>
                <addButton
                  class="add-button"
                  :toRoute="{ name: 'editPageAddItem' }"
                  :label="$t('Add item')"
                />
                <v-btn
                  v-if="userIsAdministrator || page.AllowExportForAllUsers"
                  class="mb-4 ml-0 mr-3 add-button"
                  color="info"
                  @click="exportDialog = true"
                >
                  {{ $t("Export items") }}
                  <v-icon right>mdi-arrow-top-right</v-icon>
                </v-btn>
                <v-btn
                  v-if="userIsAdministrator || page.AllowBulkUpdateForAllUsers"
                  class="mb-4 ml-0 mr-3 add-button"
                  color="warning"
                  @click="bulkUpdateDialog = true"
                >
                  {{ $t("Bulk update") }}
                  <v-icon right>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="userIsAdministrator"
                  class="mb-4 ml-0 mr-3 add-button"
                  color="error"
                  @click="deleteAllDialog = true"
                >
                  {{ $t("Delete all") }}
                  <v-icon right>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-if="userIsAdministrator"
                  class="mb-4 ml-0 mr-3 add-button"
                  color="info"
                  @click="syncDialog = true"
                >
                  {{ $t("Sync items") }}
                  <v-icon right>mdi-autorenew</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-switch
                  v-if="userIsAdministrator && !page.Custom"
                  class="mt-0"
                  v-model="editMode"
                  light
                  v-bind:label="$t('Edit mode')"
                ></v-switch>
              </v-layout>
              <v-dialog v-model="exportDialog" scrollable max-width="400px">
                <v-card>
                  <v-card-title>{{ $t("Export items") }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-5" style="height: 500px">
                    <p class="mb-3">
                      {{ $t("Select the items you wish to export.") }}
                    </p>
                    <v-form>
                      <template v-for="(item, index) in exportItems">
                        <v-checkbox
                          class="mt-0 font-weight-bold"
                          v-bind:key="'exportItems' + index"
                          v-model="item.selected"
                          :label="item.title"
                        ></v-checkbox>
                        <v-checkbox
                          class="mt-0 ml-5"
                          v-for="(
                            linkedItem, linkedItemIndex
                          ) in item.linkedExportItems"
                          v-bind:key="
                            'linkedExportItem' + linkedItemIndex + index
                          "
                          v-model="linkedItem.selected"
                          :label="linkedItem.title"
                        ></v-checkbox>
                      </template>
                    </v-form>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="exportDialog = false"
                    >
                      {{ $t("Cancel") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      v-on:click="exportPageContent()"
                    >
                      {{ $t("Export") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="bulkUpdateDialog" scrollable max-width="600px">
                <v-card>
                  <v-card-title>{{ $t("Bulk update") }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-5">
                    <v-form>
                      <h3>
                        {{ $t("Select the property you wish to update.") }}
                      </h3>

                      <v-autocomplete
                        :items="bulkUpdateItems"
                        v-model="selectedBulkUpdateProperty"
                        item-text="Title"
                        return-object
                        class="mb-3 mt-0"
                      ></v-autocomplete>

                      <h3 class="mb-3">
                        {{ $t("Enter or select the new value") }}
                      </h3>
                      <pageElement
                        v-if="selectedBulkUpdateProperty != null"
                        :item="selectedBulkUpdateProperty"
                        :websiteId="page.WebsiteId.$oid"
                        :pageId="page._id.$oid"
                        ref="pageElement"
                      />
                    </v-form>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="bulkUpdateDialog = false"
                    >
                      {{ $t("Cancel") }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      v-on:click="bulkUpdatePageContent()"
                      :loading="bulkUpdatnigContent"
                    >
                      {{ $t("Bulk update") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="deleteAllDialog" scrollable max-width="600px">
                <v-card>
                  <v-card-title>{{ $t("Delete all") }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-5">
                    <p v-if="checkDelDisable">
                      {{ $t("No items to delete!") }}
                    </p>
                    <p v-else>
                      {{ $t("Are you sure you want to delete all items?") }}
                    </p>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteAllDialog = false">
                      {{ $t("Cancel") }}
                    </v-btn>
                    <v-btn
                      color="error darken-1"
                      text
                      v-on:click="deleteAll()"
                      :loading="deleteAllContent"
                      :disabled="checkDelDisable"
                    >
                      {{ $t("Delete all") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="syncDialog"
                persistent
                scrollable
                max-width="600px"
              >
                <v-card>
                  <v-card-title>{{ $t("Sync all items") }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pt-5">
                    <p>{{ $t("Are you sure you want to sync all items?") }}</p>
                    <v-row no-gutters v-if="processingSync">
                      <v-col cols="12">
                        <strong>{{
                          `${toSyncItems.done} ${$t("of the")} 
                        ${toSyncItems.amount} ${$t("records")}`
                        }}</strong>
                      </v-col>
                      <v-col cols="12">
                        <v-progress-linear
                          v-model="toSyncItems.percentage"
                          height="16"
                        >
                        </v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="processingSync"
                      text
                      @click="syncDialog = false"
                    >
                      {{ $t("Cancel") }}
                    </v-btn>
                    <v-btn
                      color="info darken-1"
                      text
                      v-on:click="syncAllItems()"
                      :loading="processingSync"
                    >
                      {{ $t("Sync items") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-card>
                <v-card-title>
                  <v-container fluid>
                    <v-row>
                      <v-col class="pa-0 mb-3" cols="12" sm="10">
                        <h3 class="mt-3">{{ page.Name[currentLanguage] }}</h3>
                      </v-col>
                      <v-col class="pa-0 mb-3" cols="12" sm="2">
                        <v-text-field
                          class="pt-0"
                          v-if="page.AddSearchField"
                          :label="$t('Search')"
                          clearable
                          append-icon="mdi-magnify"
                          @click:clear="onSearchCleared()"
                          @keyup="searchContent"
                          v-model="searchValue"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="filters-row">
                      <!-- filters -->
                      <v-col
                        class="pa-0 pr-6"
                        cols="12"
                        sm="2"
                        v-for="(item, key, index) in getPageContentDefinition"
                        :key="index"
                      >
                        <v-select
                          :items="getBooleanValues()"
                          v-if="item['Type'] == 'boolean'"
                          clearable
                          :label="getTranslatedItemTitle(item)"
                          item-value="value"
                          item-text="label"
                          @change="filterChanged($event, key)"
                        >
                        </v-select>
                        <v-select
                          :items="item.Options"
                          v-else-if="item['Type'] == 'dropdown'"
                          clearable
                          :label="getTranslatedItemTitle(item)"
                          item-value="Id"
                          item-text="Value"
                          v-on:change="filterChanged($event, key)"
                        ></v-select>
                        <date-picker
                          name="dateFilterMenu"
                          v-else-if="item['Type'] == 'datepicker'"
                          :lableName="item.Title"
                          :dateOptions="{
                            dateClearable: true,
                            dateDisabled: false,
                            dataFieldValidation: item.FieldValidation,
                            dateAutoSelectCurrent: false,
                            dateRules: null,
                          }"
                          :dateValue="item.Value"
                          @clearDate="filterChanged(undefined, key)"
                          @changeDatePicker="filterChanged($event, key)"
                        />

                        <time-picker
                          :lableName="item.Title"
                          v-else-if="item['Type'] == 'time'"
                          :timeValue="timeFilterValue"
                          :timeOptions="{
                            timeClearable: true,
                            timeDisabled: false,
                            timeFieldValidation: item.FieldValidation,
                            timeRules: null,
                            showSeconds: item.ShowSeconds,
                          }"
                          @changedTime="filterChanged($event, key)"
                          @clearTime="filterChanged(undefined, key)"
                        />
                        <v-layout
                          align-center
                          v-else-if="item['Type'] == 'content'"
                        >
                          <v-autocomplete
                            :ref="'autocomplete' + key"
                            :items="contentFilterSearchResults[key]"
                            :loading="searchingContentFilters[key]"
                            :search-input.sync="searchContentFilterModels[key]"
                            :placeholder="
                              item.LoadItemsWhenCreated
                                ? ''
                                : $t('Search') + '...'
                            "
                            :multiple="item.MultipleItems"
                            :return-object="item.MultipleItems"
                            :chips="item.MultipleItems"
                            :deletable-chips="item.MultipleItems"
                            :item-text="showItem"
                            item-value="_id"
                            clearable
                            :label="getTranslatedItemTitle(item)"
                            hide-no-data
                            @keyup="searchContentFilter(item.LinkedPage, key)"
                            v-on:change="filterChanged($event, key)"
                            height="32px"
                            small-chips
                          >
                          </v-autocomplete>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>
                <div v-if="page.Visualization == 'rows' || !page.Visualization">
                  <v-row
                    v-for="(item, index) in content"
                    :key="item.DisplayLabel"
                    no-gutters
                  >
                    <v-col
                      subheading
                      lg="2"
                      sm="3"
                      xs="3"
                      class="pt-4 pl-3 pb-3"
                      v-if="page.ShowSortButtons"
                    >
                      <v-btn
                        class="edit-page-action-button sort-button"
                        text
                        small
                        icon
                        color="grey"
                        v-on:click="moveUp(index)"
                      >
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-btn>

                      <v-btn
                        class="edit-page-action-button sort-button"
                        text
                        small
                        icon
                        color="grey"
                        v-on:click="moveDown(index)"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      v-if="page.ShowSortButtons"
                      lg="8"
                      sm="6"
                      xs="4"
                      class="text-xs-right align-center"
                    >
                      <v-layout align-center fill-height>
                        <v-list-item-title
                          v-html="item.DisplayLabel"
                        ></v-list-item-title>
                      </v-layout>
                    </v-col>
                    <v-col
                      v-else
                      lg="10"
                      sm="9"
                      xs="7"
                      class="pl-3 text-xs-right"
                    >
                      <v-list-item-title
                        v-html="item.DisplayLabel"
                      ></v-list-item-title>
                    </v-col>
                    <v-col
                      lg="2"
                      sm="3"
                      xs="4"
                      class="pa-3 subheading text-right"
                    >
                      <router-link
                        :to="{
                          name: 'editPageAddEditItem',
                          params: { id: page._id.$oid, itemId: item._id.$oid },
                        }"
                      >
                        <v-btn icon color="orange">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </router-link>

                      <v-btn
                        icon
                        color="red"
                        @click="
                          setDeletingItem(page._id.$oid, item._id.$oid, index)
                        "
                        :loading="loadinItemToDelete[index]"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-divider v-if="index + 1 != content.length"></v-divider>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="page.Visualization == 'table'">
                  <v-data-table
                    :headers="tableHeaders"
                    :header-props="{ sortByText: $t('Sort by') }"
                    :items="tableRows"
                    :no-data-text="$t('No data available')"
                    :footer-props="{
                      itemsPerPageOptions: [-1],
                      pageText: '',
                      nextIcon: '',
                      prevIcon: '',
                    }"
                    item-key="Value"
                    :options.sync="options"
                    :custom-sort="customSort"
                  >
                    <template
                      v-for="(tableHeader, index) in tableHeaders"
                      v-slot:[`item.${tableHeader.value}`]="{ item }"
                    >
                      <div v-if="item[tableHeader.value]" :key="index">
                        <template v-if="item[tableHeader.value].AddToTableView">
                          <template
                            v-if="item[tableHeader.value].Type === 'dropdown'"
                          >
                            <v-chip
                              v-if="
                                item[tableHeader.value].ShowAsLabel &&
                                getTableDropdown(item[tableHeader.value]) != ''
                              "
                              :color="
                                getTableDropdown(item[tableHeader.value], true)
                              "
                            >
                              {{ getTableDropdown(item[tableHeader.value]) }}
                            </v-chip>
                            <div v-else>
                              {{ getTableDropdown(item[tableHeader.value]) }}
                            </div>
                          </template>

                          <template
                            v-else-if="
                              item[tableHeader.value].Type === 'boolean'
                            "
                          >
                            {{
                              item[tableHeader.value].Value
                                ? $i18n.translate("Yes")
                                : $i18n.translate("No")
                            }}
                          </template>
                          <template
                            v-else-if="
                              item[tableHeader.value].Type === 'content'
                            "
                          >
                            <!-- <v-chip
                              v-if="item[tableHeader.value].ShowAsLabel"
                              :color="
                                item[tableHeader.value].ColorLinkedPage.value
                                  .Value
                              "
                            >
                              {{ item[tableHeader.value] }}
                            </v-chip> -->
                            <template
                              v-if="item[tableHeader.value].DisplayLabels"
                            >
                              {{
                                item[tableHeader.value].DisplayLabels[
                                  currentLanguage
                                ] || item[tableHeader.value].DisplayLabels["Nl"]
                              }}
                            </template>
                            <p class="mb-0" v-else>
                              {{ item[tableHeader.value].ValueSort }}
                            </p>
                          </template>
                          <template
                            v-else-if="
                              item[tableHeader.value].Type === 'number'
                            "
                          >
                            {{ getTableNumber(item[tableHeader.value]) }}
                          </template>
                          <template
                            v-else-if="
                              item[tableHeader.value].Type === 'colorpicker'
                            "
                          >
                            {{ item[tableHeader.value].Value }}
                          </template>
                          <template
                            v-else-if="
                              item[tableHeader.value].Type === 'datepicker'
                            "
                          >
                            {{ getTableDatepicker(item[tableHeader.value]) }}
                          </template>
                          <template
                            v-else-if="item[tableHeader.value].Type === 'url'"
                          >
                            <v-layout fill-height align-center justify-start>
                              <v-img
                                contain
                                max-height="16"
                                max-width="16"
                                :src="getFavicon(item[tableHeader.value])"
                                class="mr-2"
                              >
                              </v-img>
                              <p
                                class="mb-0"
                                :class="
                                  item[tableHeader.value].Value
                                    ? 'text-decoration-underline cursor-p'
                                    : ''
                                "
                                @click="
                                  openInNewTab(item[tableHeader.value].Value)
                                "
                              >
                                {{
                                  getDomainUrl(item[tableHeader.value].Value)
                                }}
                              </p>
                            </v-layout>
                          </template>
                          <template v-else>
                            {{ getTableText(item[tableHeader.value]) }}
                          </template>
                        </template>
                      </div>
                    </template>
                    <template v-slot:[`item.sort`]="{ index }">
                      <v-btn
                        class="edit-page-action-button sort-button"
                        text
                        small
                        icon
                        color="grey"
                        v-on:click="moveUp(index)"
                      >
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-btn>

                      <v-btn
                        class="edit-page-action-button sort-button"
                        text
                        small
                        icon
                        color="grey"
                        v-on:click="moveDown(index)"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:[`item.actions`]="{ item, index }">
                      <router-link
                        v-if="
                          userIsAdministrator || page.ShowCopyBtnForAllUsers
                        "
                        :to="{
                          name: 'editPageAddCopyItem',
                          params: {
                            id: page._id.$oid,
                            itemId: item._id.$oid,
                            isCopy: true,
                          },
                        }"
                      >
                        <v-btn icon color="primary">
                          <v-icon>mdi-plus-box-multiple</v-icon>
                        </v-btn>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'editPageAddEditItem',
                          params: { id: page._id.$oid, itemId: item._id.$oid },
                        }"
                      >
                        <v-btn icon color="orange">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </router-link>

                      <v-btn
                        icon
                        color="red"
                        @click="
                          setDeletingItem(page._id.$oid, item._id.$oid, index)
                        "
                        :loading="loadinItemToDelete[index]"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </v-card>

              <infinite-loading @infinite="getContent" ref="infiniteLoading">
                <div slot="spinner"><preloader></preloader></div>
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </template>

            <!-- Edit repeating objects content definition -->
            <template v-if="userIsAdministrator && editMode">
              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Visualization") }}</h3>

                  <v-radio-group v-model="page.Visualization" mandatory>
                    <v-radio :label="$t('Rows')" value="rows"></v-radio>
                    <v-radio :label="$t('Table')" value="table"></v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>

              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Edit content definition") }}</h3>
                </v-card-text>

                <v-form ref="contentDefinitionform" lazy-validation>
                  <template v-for="(item, index) in page.ContentDefinition">
                    <v-layout
                      :key="index"
                      class="custom-overview-row mt-sm-0 mt-md-4"
                      wrap
                    >
                      <v-row>
                        <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                          <v-row no-gutters>
                            <v-layout column class="max-width-layout">
                              <v-btn
                                text
                                small
                                icon
                                color="grey"
                                v-on:click="moveUpContentDefinition(index)"
                              >
                                <v-icon>mdi-chevron-up</v-icon>
                              </v-btn>

                              <v-btn
                                text
                                small
                                icon
                                color="grey"
                                v-on:click="moveDownContentDefinition(index)"
                              >
                                <v-icon>mdi-chevron-down</v-icon>
                              </v-btn>
                            </v-layout>
                            <h4
                              class="
                                mt-4
                                text-right
                                text-sm-left
                                text-md-left
                                text-lg-left
                                text-xl-left
                                pr-0 pr-sm-4 pr-md-4 pr-lg-4 pr-xl-4
                              "
                            >
                              {{ index }}
                            </h4>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                          <v-row no-gutters>
                            <v-spacer></v-spacer>
                            <v-col cols="8" sm="5" md="5" lg="5" class="pl-2">
                              <configLanguageSelector
                                :colOption="{
                                  colsDropdown: 3,
                                  colsText: 9,
                                }"
                                :itemData="item"
                                @changeLanguage="selectedLang[index] = $event"
                                @changeTranslatedTitle="
                                  item.TranslatedTitle = $event
                                "
                              />

                              <template v-if="item.Type == 'content'">
                                <v-select
                                  :items="getSharedPagesNotAssigned()"
                                  single-line
                                  v-model="item.LinkedPage"
                                  item-value="_id"
                                  :label="$t('Select a page')"
                                  class="altSelect"
                                  @change="
                                    getContentFromLinkedPage(item.LinkedPage)
                                  "
                                >
                                  <template slot="selection" slot-scope="data">
                                    <div class="input-group__selections__comma">
                                      {{ data.item.Name[currentLanguage] }}
                                    </div>
                                  </template>
                                  <template slot="item" slot-scope="data">
                                    {{ data.item.Name[currentLanguage] }}
                                  </template>
                                </v-select>
                                <!--  <v-select
                                    v-if="item.ShowAsLabel"
                                    :items="getLinkedPageContentItems"
                                    single-line
                                    v-model="item.ColorLinkedPage"
                                    item-text="value"
                                    item-value="id"
                                    :label="$t('Select Color')"
                                    class="altSelect"
                                  >
                                    <template
                                      slot="selection"
                                      slot-scope="data"
                                    >
                                      <div
                                        class="input-group__selections__comma"
                                      >
                                        {{ data.item.Name[currentLanguage] }}
                                      </div>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                      {{ data.item.Name[currentLanguage] }}
                                    </template>
                                  </v-select>
                                </template>
                                <template
                                  v-else-if="item.Type == 'croppedimage'"
                                >
                                  <v-text-field
                                    :label="$t('Width in pixels')"
                                    v-model="item.Width"
                                    class="float-left mr-3 ml-4"
                                  ></v-text-field>

                                  <v-text-field
                                    :label="$t('Height in pixels')"
                                    v-model="item.Height"
                                  ></v-text-field>
                                </template>
                                <template
                                  v-else-if="item.Type == 'colorpicker'"
                                >
                                  <v-color-picker
                                    v-model="item.Value"
                                    class="ml-4"
                                    dot-size="25"
                                    mode="hexa"
                                    swatches-max-height="200"
                                  ></v-color-picker>
                                </template>
                              </v-col>
                              <v-col>
                                <h4 class="mt-4 mb-4 item-type">
                                  {{ item.Type }}
                                </h4>
                                <p
                                  v-if="item.Type == 'colorpicker'"
                                  class="mt-6 mb-4 ml-4"
                                >
                                  {{ $t("default value") }}
                                </p>
                              </v-col>
                              <v-col>
                                <v-switch
                                  v-if="
                                    item.Type == 'text' ||
                                    item.Type == 'richtext' ||
                                    item.Type == 'image' ||
                                    item.Type == 'croppedimage' ||
                                    item.Type == 'gallery'
                                  "
                                  v-model="item.Translate"
                                  class="mt-3 ml-2 mb-0"
                                  light
                                  v-bind:label="$t('Translate item')"
                                ></v-switch>

                                <v-switch
                                  v-if="
                                    item.Type == 'image' ||
                                    item.Type == 'croppedimage' ||
                                    item.Type == 'gallery'
                                  "
                                  v-model="item.AddAltTags"
                                  class="mt-0 ml-2"
                                  :label="$t('Add alt tags')"
                                >
                                </v-switch>

                                <v-switch
                                  v-if="item.Type == 'content'"
                                  class="mt-0 ml-2"
                                  v-model="item.LoadItemsWhenCreated"
                                  :label="$t('Load items when created')"
                                ></v-switch>

                                <v-switch
                                  v-if="item.Type == 'content'"
                                  class="mt-0 ml-2"
                                  v-model="item.MultipleItems"
                                  :label="$t('Multiple items')"
                                ></v-switch>

                                <v-switch
                                  v-if="item.Type == 'time'"
                                  class="mt-0 ml-2"
                                  v-model="item.ShowSeconds"
                                  :label="$t('Show seconds')"
                                ></v-switch>

                                <v-switch
                                  v-if="item.Type != 'custom'"
                                  class="mt-0 ml-2"
                                  v-model="item.ReadOnly"
                                  :label="$t('Read-only')"
                                ></v-switch>

                                <v-switch
                                  v-if="
                                    page.Visualization == 'table' &&
                                    (item.Type == 'text' ||
                                      item.Type == 'richtext' ||
                                      item.Type == 'datepicker' ||
                                      item.Type == 'time' ||
                                      item.Type == 'dropdown' ||
                                      item.Type == 'boolean' ||
                                      item.Type == 'content' ||
                                      item.Type == 'number' ||
                                      item.Type == 'custom' ||
                                      item.Type == 'colorpicker')
                                  "
                                  v-model="item.AddToTableView"
                                  class="mt-0 ml-2"
                                  :label="$t('Add to table view')"
                                >
                                </v-switch>

                                <v-switch
                                  v-if="
                                    item.Type == 'content' ||
                                    item.Type == 'dropdown' ||
                                    item.Type == 'boolean' ||
                                    item.Type == 'datepicker' ||
                                    item.Type == 'time'
                                  "
                                  v-model="item.ShowFilter"
                                  class="mt-0 ml-2"
                                  :label="$t('Show filter')"
                                >
                                </v-switch>
                                <v-switch
                                  v-if="
                                    item.Type == 'datepicker' ||
                                    item.Type == 'time'
                                  "
                                  v-model="item.AutoSelectCurrent"
                                  :label="item.Type == 'datepicker'?$t('Auto select date'):$t('Auto select time')"
                                  class="mt-0 ml-2"
                                >
                                </v-switch>
                                <v-switch
                                  v-if="item.Type == 'number'"
                                  v-model="item.NeedsNumberSign"
                                  :label="$t('Show currency symbol')"
                                  class="mt-0 ml-2"
                                >
                                </v-switch>
                                <v-switch
                                  v-if="
                                    item.Type == 'number' &&
                                    item.NeedsNumberSign
                                  "
                                  v-model="item.IsPrefix"
                                  :label="$t('Show currency symbol in front')"
                                  class="mt-0 ml-7"
                                >
                                </v-switch>

                                <v-switch
                                  v-if="
                                    item.Type == 'text' ||
                                    item.Type == 'richtext' ||
                                    item.Type == 'datepicker' ||
                                    item.Type == 'time' ||
                                    item.Type == 'dropdown' ||
                                    item.Type == 'number'
                                  "
                                  v-model="item.FieldValidation"
                                  @change="changeFieldValidation(item)"
                                  class="mt-0 ml-2"
                                  :label="$t('Field validation')"
                                >
                                </v-switch>
                                <v-switch
                                  v-if="item.Type == 'files'"
                                  class="mt-0 ml-2"
                                  v-model="item.SpecificFile"
                                  :label="$t('Set file types')"
                                ></v-switch>
                                <v-row no-gutters v-if="item.NeedsNumberSign">
                                  <v-col cols="12" class="mt-0 ml-2">
                                    <v-select
                                      v-model="item.NumberSign"
                                      :items="$config.signs"
                                      dense
                                      :label="$t('Symbol')"
                                    ></v-select>
                                  </v-col>
                                </v-row>

                                <v-row no-gutters v-if="item.SpecificFile">
                                  <v-col cols="5" class="mt-0 ml-2">
                                    <v-select
                                      :items="$config.fileTypes"
                                      v-model="item.SpecificFileTypes"
                                      :label="$t('Files')"
                                      multiple
                                      chips
                                      clearable
                                    >
                                    </v-select>
                                  </v-col>
                                </v-row>
                                <v-row no-gutters v-if="item.FieldValidation">
                                  <v-col cols="12" class="mt-0 ml-2">
                                    <v-combobox
                                      :items="
                                        validations.getAllValidationNames()
                                      "
                                      :label="$t('Rule')"
                                      v-model="item.FieldValidationFuntion"
                                      clearable
                                      @click:clear="
                                        item.FieldValidationFuntion = ''
                                      "
                                    >
                                    </v-combobox>
                                  </v-col>
                                </v-row>
                                  </v-select> -->
                              </template>
                              <template v-else-if="item.Type == 'croppedimage'">
                                <v-row no-gutters>
                                  <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="6"
                                    xl="6"
                                  >
                                    <v-text-field
                                      :label="$t('Width in pixels')"
                                      v-model="item.Width"
                                      class="mr-md-3 mr-lg-3 mr-xl-3"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="6"
                                    xl="6"
                                  >
                                    <v-text-field
                                      :label="$t('Height in pixels')"
                                      v-model="item.Height"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </template>
                              <template v-else-if="item.Type == 'colorpicker'">
                                <v-color-picker
                                  v-model="item.Value"
                                  class="ml-4"
                                  dot-size="25"
                                  mode="hexa"
                                  swatches-max-height="200"
                                ></v-color-picker>
                              </template>

                              <template v-if="item.Type == 'dropdown'">
                                <v-row no-gutters>
                                  <v-col cols="12" class="mb-4">
                                    <b>
                                      <i>
                                        {{ $t("Configure dropdown options") }}:
                                      </i>
                                    </b>
                                  </v-col>
                                  <v-col class="mb-4">
                                    <v-chip
                                      v-for="(
                                        option, optionIndex
                                      ) in item.Options"
                                      :key="optionIndex"
                                      close
                                      @click:close="
                                        removeOption(index, optionIndex)
                                      "
                                    >
                                      {{ option.Value }}
                                    </v-chip>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-row no-gutters>
                                      <v-col cols="12">
                                        <v-text-field
                                          :label="$t('Option name')"
                                          v-model="newOptionName"
                                          :error="addOptionFieldHasError"
                                          :rules="addOptionFieldRule"
                                          class="no-styling ml-1"
                                          hide-details="auto"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" class="mb-4">
                                        <v-btn
                                          class="mt-3"
                                          color="success"
                                          @click="addOption(index)"
                                        >
                                          {{ $t("Add option") }}
                                          <v-icon right>mdi-plus</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-col>
                            <v-col cols="4" sm="2" md="2" lg="2" class="pl-3">
                              <h4
                                class="
                                  mt-4
                                  mb-4
                                  text-right
                                  text-sm-left
                                  text-md-left
                                  text-lg-left
                                  text-xl-left
                                  pr-0 pr-md-4 pr-lg-4 pr-xl-4
                                "
                              >
                                {{ item.Type }}
                              </h4>
                              <p
                                v-if="item.Type == 'colorpicker'"
                                class="mt-6 mb-4 ml-4"
                              >
                                {{ $t("default value") }}
                              </p>
                            </v-col>
                            <v-col cols="12" sm="4" md="4" lg="4">
                              <v-switch
                                v-if="
                                  item.Type == 'text' ||
                                  item.Type == 'richtext' ||
                                  item.Type == 'image' ||
                                  item.Type == 'croppedimage' ||
                                  item.Type == 'gallery'
                                "
                                v-model="item.Translate"
                                class="mt-3 ml-2 mb-0"
                                light
                                v-bind:label="$t('Translate item')"
                              ></v-switch>

                              <v-switch
                                v-if="
                                  item.Type == 'image' ||
                                  item.Type == 'croppedimage' ||
                                  item.Type == 'gallery'
                                "
                                v-model="item.AddAltTags"
                                class="mt-0 ml-2"
                                :label="$t('Add alt tags')"
                              >
                              </v-switch>

                              <v-switch
                                v-if="item.Type == 'content'"
                                class="mt-0 ml-2"
                                v-model="item.LoadItemsWhenCreated"
                                :label="$t('Load items when created')"
                              ></v-switch>

                              <v-switch
                                v-if="item.Type == 'content'"
                                class="mt-0 ml-2"
                                v-model="item.MultipleItems"
                                :label="$t('Multiple items')"
                              ></v-switch>
                              <v-switch
                                v-if="item.Type == 'content'"
                                class="mt-0 ml-2"
                                v-model="item.LinkedContentDetailButton"
                                :disabled="item.MultipleItems"
                                :label="$t('Detail button')"
                              ></v-switch>

                              <v-switch
                                v-if="item.Type == 'time'"
                                class="mt-0 ml-2"
                                v-model="item.ShowSeconds"
                                :label="$t('Show seconds')"
                              ></v-switch>

                              <v-switch
                                v-if="item.Type != 'custom'"
                                class="mt-0 ml-2"
                                v-model="item.ReadOnly"
                                :label="$t('Read-only')"
                              ></v-switch>

                              <v-switch
                                v-if="
                                  page.Visualization == 'table' &&
                                  (item.Type == 'text' ||
                                    item.Type == 'richtext' ||
                                    item.Type == 'datepicker' ||
                                    item.Type == 'time' ||
                                    item.Type == 'dropdown' ||
                                    item.Type == 'boolean' ||
                                    item.Type == 'content' ||
                                    item.Type == 'number' ||
                                    item.Type == 'custom' ||
                                    item.Type == 'url' ||
                                    item.Type == 'colorpicker')
                                "
                                v-model="item.AddToTableView"
                                class="mt-0 ml-2"
                                :label="$t('Add to table view')"
                              >
                              </v-switch>

                              <v-switch
                                v-if="
                                  item.Type == 'content' ||
                                  item.Type == 'dropdown' ||
                                  item.Type == 'boolean' ||
                                  item.Type == 'datepicker' ||
                                  item.Type == 'time'
                                "
                                v-model="item.ShowFilter"
                                class="mt-0 ml-2"
                                :label="$t('Show filter')"
                              >
                              </v-switch>

                              <!--<v-switch
                                v-if="item.Type == 'text'"
                                v-model="item.Encrypted"
                                class="mt-0 ml-2"
                                :label="$t('Encrypted Field')"
                              >
                              </v-switch>-->
                              <v-switch
                                v-if="
                                  item.Type == 'datepicker' ||
                                  item.Type == 'time'
                                "
                                v-model="item.AutoSelectCurrent"
                                :label="
                                  item.Type == 'datepicker'
                                    ? $t('Auto select date')
                                    : $t('Auto select time')
                                "
                                class="mt-0 ml-2"
                              >
                              </v-switch>
                              <v-divider
                                v-if="
                                  item.Type == 'number' && item.NeedsNumberSign
                                "
                                class="mb-4"
                              ></v-divider>
                              <v-switch
                                v-if="item.Type == 'number'"
                                v-model="item.NeedsNumberSign"
                                :label="$t('Show currency symbol')"
                                class="mt-0 ml-2"
                              >
                              </v-switch>
                              <v-switch
                                v-if="
                                  item.Type == 'number' && item.NeedsNumberSign
                                "
                                v-model="item.IsPrefix"
                                :label="$t('Show currency symbol in front')"
                                class="mt-0 ml-2"
                              >
                              </v-switch>
                              <v-row no-gutters v-if="item.NeedsNumberSign">
                                <v-col cols="10" class="mt-0 ml-2">
                                  <v-select
                                    v-model="item.NumberSign"
                                    :items="$config.signs"
                                    dense
                                    :label="$t('Symbol')"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-divider
                                class="mb-4"
                                v-if="
                                  item.Type == 'number' && item.NeedsNumberSign
                                "
                              ></v-divider>
                              <v-switch
                                v-if="
                                  item.Type == 'text' ||
                                  item.Type == 'richtext' ||
                                  item.Type == 'datepicker' ||
                                  item.Type == 'time' ||
                                  item.Type == 'dropdown' ||
                                  item.Type == 'number'
                                "
                                v-model="item.FieldValidation"
                                @change="changeFieldValidation(item)"
                                class="mt-0 ml-2"
                                :label="$t('Field validation')"
                              >
                              </v-switch>
                              <v-switch
                                v-if="item.Type == 'files'"
                                class="mt-0 ml-2"
                                v-model="item.TranslatedFiles"
                                :label="$t('Translate files')"
                              ></v-switch>
                              <v-switch
                                v-if="item.Type == 'files'"
                                class="mt-0 ml-2"
                                v-model="item.SpecificFile"
                                :label="$t('Set file types')"
                              ></v-switch>

                              <v-row no-gutters v-if="item.SpecificFile">
                                <v-col cols="5" class="mt-0 ml-2">
                                  <v-select
                                    :items="$config.fileTypes"
                                    v-model="item.SpecificFileTypes"
                                    :label="$t('Files')"
                                    multiple
                                    chips
                                    clearable
                                  >
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row no-gutters v-if="item.FieldValidation">
                                <v-col cols="8" class="mt-0 ml-2">
                                  <v-combobox
                                    :items="validations.getAllValidationNames()"
                                    :label="$t('Rule')"
                                    v-model="item.FieldValidationFuntion"
                                    clearable
                                    @click:clear="
                                      item.FieldValidationFuntion = ''
                                    "
                                  >
                                  </v-combobox>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col cols="5" class="mt-0 ml-2">
                                  <v-text-field
                                    v-model.number="item.colWidth"
                                    type="number"
                                    max="12"
                                    min="1"
                                    dense
                                    :label="$t('Column width')"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="1"
                          md="1"
                          lg="1"
                          xl="1"
                          class="d-flex align-center justify-end"
                        >
                          <v-btn
                            v-on:click="removeContentDefinitionItem(index)"
                            icon
                            color="red"
                            class="mr-md-3 mr-lg-3 mr-xl-3"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </template>
                </v-form>

                <v-card-text class="border-top">
                  <h3 class="mb-3 mt-5">
                    {{ $t("Add element to content definition") }}
                  </h3>

                  <addRemovePageElement
                    @addItem="addObjectToContentDefinition"
                    :enableAdd="true"
                  />
                </v-card-text>
              </v-card>

              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Options") }}</h3>

                  <v-switch
                    v-model="page.AddSearchField"
                    light
                    :label="$t('Add search field')"
                  ></v-switch>

                  <v-switch
                    v-model="page.AllowExportForAllUsers"
                    light
                    :label="$t('Allow export for all users')"
                  ></v-switch>

                  <v-switch
                    v-model="page.ShowSortButtons"
                    :disabled="page.MostRecentItemsAtTheTop"
                    light
                    :label="$t('Show sort buttons')"
                  ></v-switch>

                  <v-switch
                    v-model="page.AllowBulkUpdateForAllUsers"
                    light
                    :label="$t('Allow bulk update for all users')"
                  ></v-switch>
                  <v-switch
                    v-model="page.MostRecentItemsAtTheTop"
                    light
                    @change="
                      page.MostRecentItemsAtTheTop
                        ? $set(page, 'ShowSortButtons', false)
                        : ''
                    "
                    :label="$t('Show most recent items at the top')"
                  ></v-switch>
                  <v-switch
                    v-model="page.ShowCopyBtnForAllUsers"
                    light
                    :label="$t('Show copy button for all users')"
                  ></v-switch>
                  <v-row no-gutters>
                    <v-col cols="12" md="3" class="d-flex">
                      <v-text-field
                        v-model="page.DisplayLabelCustomFormat"
                        :label="$t('Display label custom format')"
                        :placeholder="
                          '{'.charAt(0) +
                          `${$t('Name')}` +
                          '}'.charAt(0) +
                          `${$t('propertyname')}` +
                          '{'.charAt(0) +
                          `${$t('Name')}` +
                          '}'.charAt(0) +
                          `${$t('propertyname')}`
                        "
                        clearable
                      ></v-text-field>

                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="grey" dark v-bind="attrs" v-on="on">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>

                        <span>
                          {{
                            "{".charAt(0) +
                            `${$t("Name")}` +
                            "}".charAt(0) +
                            `${$t("propertyname")}` +
                            "{".charAt(0) +
                            `${$t("Name")}` +
                            "}".charAt(0) +
                            `${$t("propertyname")}`
                          }}
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-text>
                  <h3 class="mb-3">{{ $t("Integrations") }}</h3>
                  <mailchimp-integration
                    :page="page"
                    :currentLanguage="currentLanguage"
                    @updatePageMailchimpIntegration="
                      page.MailChimpIntegration = $event
                    "
                  />
                </v-card-text>
              </v-card>
            </template>
          </template>

          <template v-if="page.RepeatingContent && editMode">
            <cancelConfirmButtons
              :confirmFunction="saveContentDefinition"
              :loading="savingContentDefinition"
              :confirmLabel="$t('Save page definition')"
              @goBack="goBack"
            />
          </template>
          <template v-else-if="!page.RepeatingContent && !page.Custom">
            <cancelConfirmButtons
              ref="cancelConfirm"
              :isNonRepeatingPage="true"
              :confirmFunction="saveContent"
              :loading="savingContent"
              :confirmLabel="$t('Save page')"
              @goBack="goBack"
            />
          </template>
        </template>
        <template v-else-if="error">
          <preloader></preloader>
        </template>
        <v-dialog v-model="deleteItemDialog" max-width="600px">
          <v-card>
            <v-card-title>
              {{ $t("Delete") }}
            </v-card-title>
            <v-card-text>
              <span
                v-if="itemToDelete.displayLabel"
                v-html="
                  $t('Delete page item', { label: itemToDelete.displayLabel })
                "
              ></span>
              <span v-else>
                {{ $t("Are you sure you want to delete the item?") }}
              </span>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="deleteItemDialog = false">
                {{ $t("Cancel") }}
              </v-btn>
              <v-btn
                :loading="deleting"
                color="error"
                @click="deletePageItem()"
              >
                {{ $t("Delete") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <errorHandeling
          v-if="success.length > 0"
          :snackbarText="success"
          :buttons="[
            {
              isText: true,
              functionName: 'clearError',
              text: 'Close',
            },
          ]"
          snackbarColor="success"
          snackbarTimout="-1"
          snackbarIcon="mdi-check"
          @clearError="success = ''"
        />
        <errorHandeling
          v-if="setSyncPageSnackbar"
          :buttons="[
            {
              isText: true,
              functionName: '',
              text: 'Sync Items',
            },
            {
              isText: true,
              functionName: 'clearError',
              text: 'Close',
            },
          ]"
          :snackbarText="
            $t(
              'Do you wish to synchronize the content with the latest page definitions?'
            )
          "
          @syncItems="setSyncDialog()"
          snackbarColor="warning"
          snackbarTimout="-1"
          snackbarIcon="mdi-alert"
          @clearError="clearSyncWarning()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import request from "../../request";
import helpers from "../../helpers";
import validation from "../../validation";
import auth from "../../auth";
import { mapGetters } from "vuex";

import InfiniteLoading from "vue-infinite-loading";
import MailchimpIntegration from "../integrations/MailchimpIntegration.vue";
export default {
  data() {
    return {
      success: "",
      selectedLang: {},
      userToken:
        localStorage.user == null ? "" : JSON.parse(localStorage.user).Token,
      page: null,
      linkedPages: null,
      content: null,
      userIsAdministrator: auth.userIsAdministrator(),
      editMode: false,
      froalaOptions: {
        toolbar: [
          "bold",
          "italic",
          "underline",
          "|",
          "align",
          "formatOL",
          "formatUL",
        ],
        toolbarMD: [
          "bold",
          "italic",
          "underline",
          "|",
          "align",
          "formatOL",
          "formatUL",
        ],
      },
      file: null,
      objectTypes: this.$config.objectTypes,
      newOptionName: "",
      newOptionColor: "",
      savingContent: false,
      savingContentDefinition: false,
      addOptionFieldHasError: false,
      addOptionFieldRule: [],
      options: {},
      tableSortBy: "",
      tableSortDescending: false,
      contentIsLoading: false,
      searchValue: "",
      searchTimeout: null,
      dateFilterMenu: false,
      dateFilterValue: null,
      timeFilterMenu: false,
      timeFilterValue: null,
      contentFilterSearchResults: [],
      searchingContentFilters: [],
      searchContentFilterModels: [],
      pageFilters: [],
      exportDialog: false,
      selectedBulkUpdateProperty: null,
      bulkUpdateDialog: false,
      bulkUpdatnigContent: false,
      deleteAllDialog: false,
      deleteAllContent: false,
      syncDialog: false,
      processingSync: false,
      prevUrl: null,
      prevRoute: "",
      validations: validation,
      deleteItemDialog: false,
      toSyncItems: { amount: 0, done: 0, percentage: 0 },

      linkedPageContentItems: null,
      itemToDelete: {},
      deleting: false,
      loadinItemToDelete: [],
      setSyncPageSnackbar: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
    });
  },
  created() {
    this.getPage();
  },

  watch: {
    "$route.params.id": function () {
      this.getPage();
    },
    options: {
      handler() {
        if (this.content) {
          this.content = null;
          this.$refs.infiniteLoading.stateChanger.reset();
          this.getContent();
        }
      },
    },
    deep: true,
    content: {
      // This will let Vue know to look inside the object
      deep: true,
      handler(newVal, oldVal) {
        if (
          newVal &&
          oldVal &&
          !this.page.RepeatingContent &&
          !this.page.Custom
        ) {
          this.$refs.cancelConfirm.cancelDisableToggle();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      error: "error",
      selectedWebsite: "selectedWebsite",
    }),
    getLinkedPageContentItems: {
      get() {
        return this.linkedPageContentItems;
      },
      set(newItems) {
        this.linkedPageContentItems = newItems;
      },
    },
    checkDelDisable() {
      if (!this.content) {
        return true;
      }
      if (this.content.length == 0) {
        return true;
      }
      return false;
    },
    getDDMMYYFormat: {
      get: function () {
        let date;
        if (this.dateFilterValue) {
          const [year, month, day] = this.dateFilterValue.split("-");
          date = day + "-" + month + "-" + year;
        }
        return date;
      },
      set: function (newValue) {
        this.dateFilterValue = newValue;
      },
    },
    getContentData() {
      let keys = Object.keys(this.content[0].Data);
      let topRowArray = [];
      let counter = 0;
      let indexArray = [];
      //loop over keys with index
      keys.forEach((element, index) => {
        if (
          this.content[0].Data[element].Required &&
          (this.content[0].Data[element].FieldValidation == undefined ||
            this.content[0].Data[element].FieldValidation == null) &&
          (this.content[0].Data[element].Type == "text" ||
            this.content[0].Data[element].Type == "richText")
        ) {
          this.content[0].Data[element].FieldValidation = true;
          this.$set(
            this.content[0].Data[element],
            "FieldValidationFuntion",
            "notEmptyStringRule"
          );
          /*  this.content[0].Data[element].FieldValidationFuntion =
            "notEmptyStringRule"; */
        }
        if (
          this.content[0].Data[element].Required &&
          (this.content[0].Data[element].FieldValidation == undefined ||
            this.content[0].Data[element].FieldValidation == null) &&
          this.content[0].Data[element].Type == "number"
        ) {
          this.content[0].Data[element].FieldValidation = true;
          this.$set(
            this.content[0].Data[element],
            "FieldValidationFuntion",
            "notEmptyStringRule"
          );
        }
        //check if contectdata has colWidth property, else push it in topRowArray (auto colWidth of 12)
        if (this.content[0].Data[element].colWidth) {
          //adding counter and item to index array.
          //For counter: try to get at 12 for one full row
          //For index array is to get all the previous item in the row
          counter += this.content[0].Data[element].colWidth;
          indexArray.push(index);
          if (counter % 12 > 0 && counter > 12) {
            // 2 states to pass counter is 12 counter is above 12 and is to big so push item and push index array
            //used when row has cols that are less then 12 cols long
            indexArray.pop();
            let itemsForArray = {};
            indexArray.forEach((el) => {
              itemsForArray[keys[el]] = this.content[0].Data[keys[el]];
            });
            indexArray = [];
            topRowArray.push(itemsForArray);
            let item = {};
            item[element] = this.content[0].Data[keys[index]];
            topRowArray.push(item);
            counter = 0;
          } else if (
            counter % 12 == 0 ||
            keys.length == 1 ||
            (index + 1 == keys.length && counter % 12)
          ) {
            // 3 states to pass is %12 or array is one item or is last item of array and not 12 long col
            let itemsForArray = {};
            indexArray.forEach((el) => {
              itemsForArray[keys[el]] = this.content[0].Data[keys[el]];
            });
            indexArray = [];
            topRowArray.push(itemsForArray);
            counter = 0;
          }
        } else {
          let item = {};
          this.content[0].Data[element]["colWidth"] = 12;
          item[element] = this.content[0].Data[element];
          topRowArray.push(item);
          counter = 0;
        }
      });
      return topRowArray;
    },
    getBCPLangCode() {
      let lang;
      switch (this.currentLanguage) {
        case "Nl":
          lang = "nl-BE";
          break;
        case "Fr":
          lang = "fr-FR";
          break;
        case "En":
          lang = "en-GB";
          break;
      }

      return lang;
    },
    currentLanguage: {
      get() {
        return this.$i18n.locale();
      },
    },
    customComponent() {
      return () =>
        this.page && this.page.Custom
          ? import("@/components/custom/" + this.page.UniqueName + ".vue")
          : null;
    },
    tableHeaders() {
      if (this.page.Visualization == "table" && this.page.ContentDefinition) {
        var headers = [];

        if (this.page.ShowSortButtons) {
          headers.push({
            text: this.$i18n.translate("Sort"),
            value: "sort",
            sortable: false,
          });
        }

        for (let value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].AddToTableView) {
            if (this.page.ContentDefinition[value].TranslatedTitle) {
              if (
                this.page.ContentDefinition[value].TranslatedTitle[
                  this.currentLanguage
                ] == ""
              ) {
                headers.push({
                  text: this.page.ContentDefinition[value].TranslatedTitle[
                    "Nl"
                  ],
                  value: value,
                });
              } else {
                headers.push({
                  text: this.page.ContentDefinition[value].TranslatedTitle[
                    this.currentLanguage
                  ],
                  value: value,
                });
              }
            } else {
              headers.push({
                text: this.page.ContentDefinition[value].Title,
                value: value,
              });
            }
          }
        }

        headers.push({
          text: this.$i18n.translate("Actions"),
          value: "actions",
          sortable: false,
          align: "right",
        });
        return headers;
      } else {
        return [];
      }
    },
    tableRows() {
      if (this.page.Visualization == "table" && this.content) {
        let rows = [];
        for (let value in this.content) {
          let row = {};
          row["_id"] = this.content[value]._id;
          row["LastUpdated"] = this.content[value].LastUpdated.$date;
          for (let dataValue in this.content[value].Data) {
            row[dataValue] = {
              ...this.content[value].Data[dataValue],
              AddToTableView:
                this.content[value].Data[dataValue].AddToTableView,
            };
          }
          rows.push(row);
        }
        return rows;
      } else {
        return [];
      }
    },
    exportItems() {
      var exportItems = this.getExportItemsFromPage(this.page);
      if (this.linkedPages) {
        for (var value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].LinkedPage) {
            var linkedPage = this.linkedPages.filter((obj) => {
              return (
                obj._id.$oid ==
                this.page.ContentDefinition[value].LinkedPage.$oid
              );
            })[0];
            if (linkedPage) {
              var index = exportItems.findIndex((obj) => obj.value == value);
              exportItems[index].linkedExportItems =
                this.getExportItemsFromPage(linkedPage, true);
            }
          }
        }
      }
      return exportItems;
    },
    bulkUpdateItems() {
      if (this.page.RepeatingContent) {
        var items = [];
        for (var value in this.page.ContentDefinition) {
          if (
            this.page.ContentDefinition[value].Type != "image" &&
            this.page.ContentDefinition[value].Type != "croppedimage" &&
            this.page.ContentDefinition[value].Type != "gallery" &&
            this.page.ContentDefinition[value].Type != "files"
          ) {
            items.push(this.page.ContentDefinition[value]);
          }
        }
        return items;
      }
      return [];
    },
    getPageContentDefinition() {
      let pageContent = {};
      for (const key in this.page.ContentDefinition) {
        if (
          this.page.ContentDefinition[key].ShowFilter &&
          this.page.Visualization == "table"
        ) {
          pageContent[key] = this.page.ContentDefinition[key];
        }
      }
      return pageContent;
    },
  },
  components: {
    InfiniteLoading,
    MailchimpIntegration,
  },
  methods: {
    clearSyncWarning() {
      this.setSyncPageSnackbar = false;
      this.$store.dispatch("resetError");
    },
    updateCheckbox(index) {
      if (!this.integrationCheckbox[index]) {
        this.$set(this.integrationShow, index, false);
      }
    },
    getFavicon(item) {
      return helpers.getFaviconFromGoogle(item);
    },
    getDomainUrl(item) {
      return helpers.getDomainUrl(item);
    },
    openInNewTab(item) {
      if (!item) return;
      window.open(item, "_blank").focus();
    },
    changeFieldValidation(item) {
      !item.FieldValidation
        ? (item["FieldValidationFuntion"] = null)
        : (item["FieldValidationFuntion"] = "notEmptyStringRule");
    },
    goBack() {
      if (!this.page.RepeatingContent && !this.page.Custom) {
        location.reload();
      } else {
        this.editMode = false;
      }
    },
    getTranslatedItemTitle(item) {
      if (!item.TranslatedTitle) {
        return item.Title;
      }
      return item.TranslatedTitle[this.currentLanguage]
        ? item.TranslatedTitle[this.currentLanguage]
        : item.TranslatedTitle["Nl"];
    },
    saveContentDefinition() {
      if (this.$refs.contentDefinitionform.validate()) {
        //used when saving the content definition
        this.savingContentDefinition = true;
        //if there is no TranslatedTitle in object add one with title
        for (const key in this.page.ContentDefinition) {
          if (!this.page.ContentDefinition[key]["TranslatedTitle"]) {
            this.page.ContentDefinition[key]["TranslatedTitle"] = {
              Nl: "",
              Fr: "",
              En: "",
            };
            this.page.ContentDefinition[key]["TranslatedTitle"][
              this.selectedLang[key]
            ] = this.page.ContentDefinition[key].Title;
          }
        }
        request.put("/page", this.page, (res) => {
          this.savingContentDefinition = false;

          if (res.success) {
            this.editMode = false;
            this.setSyncPageSnackbar = true;
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      }
    },
    saveContent() {
      //used when saving a page with non-repeating objects
      this.$store.dispatch("resetError");

      var formCustomElementsValid =
        helpers.validateFormPageElements("editPageForm");
      var formIsValid = this.$refs.form.validate();

      if (formCustomElementsValid && formIsValid) {
        this.savingContent = true;
        this.content[0].DisplayLabel = helpers.getDisplayLabel(this.content[0]);
        request.put("/content", this.content[0], (res) => {
          this.savingContent = false;

          if (res.success) {
            if (
              this.page.SynchronizationPutUrl &&
              this.page.SynchronizationPutUrl.length > 0
            ) {
              request.put(
                this.page.SynchronizationPutUrl,
                this.content[0],
                (res) => {
                  if (!res.success) {
                    this.$store.dispatch("setError", res);
                  }
                }
              );
            }
            this.success = this.$i18n.translate(
              "The page was successfully saved"
            );
            setTimeout(() => {
              this.success = "";
            }, this.$config.hideMessageTimeout);
          }
        });
      } else {
        this.$store.dispatch(
          "setError",
          this.$i18n.translate("Please make sure all text fields have a value")
        );
      }
    },
    getPage() {
      this.page == null;
      request.get("/page/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          this.page = res.data;
          this.$store.dispatch("setSelectedWebsiteById", this.page.WebsiteId);
          if (!this.page.Custom) {
            if (!this.page.RepeatingContent) {
              this.getPageContent();
            } else {
              if (this.page.ContentDefinition == null) {
                this.page.ContentDefinition = {};
              }
            }
          }
          //Get content filter data
          for (let value in this.page.ContentDefinition) {
            if (
              this.page.ContentDefinition[value].Type == "content" &&
              this.page.ContentDefinition[value].LoadItemsWhenCreated
            ) {
              this.searchingContentFilters[value] = false;
              this.searchContentFilterModels[value] = "";
              this.searchContentFilter(
                this.page.ContentDefinition[value].LinkedPage,
                value
              );
            }
          }

          //Get linked pages
          if (this.page.RepeatingContent && this.$route.params.id) {
            request.get(
              "/linkedpages/" + this.$route.params.id,
              null,
              (res) => {
                if (res.success) {
                  this.linkedPages = res.data;
                  this.linkedPages.forEach((element) => {
                    let arr = [];
                    for (const key in element.ContentDefinition) {
                      if (
                        element.ContentDefinition[key].Type === "colorpicker"
                      ) {
                        const lang = element.ContentDefinition[key]
                          .TranslatedTitle[this.currentLanguage]
                          ? element.ContentDefinition[key].TranslatedTitle[
                              this.currentLanguage
                            ]
                          : element.ContentDefinition[key].TranslatedTitle[
                              "Nl"
                            ];
                        arr.push({ id: key, value: lang });
                      }
                    }
                    this.linkedPageContentItems = arr;
                  });
                } else {
                  this.$store.dispatch("setError", res);
                }
              }
            );
          }
        } else {
          this.$store.dispatch("setError", res);
          this.$router.push({ name: "pagenotfound" });
          this.$router.go(1);
        }
      });
    },
    getPageContent() {
      if (!this.$route.params.id) {
        return;
      }
      this.content == null;

      //Content = array of content objects
      //This function is preferably used on pages with non-repeating objects
      request.get(
        "/pagecontent/" +
          this.$route.params.id +
          "?language=" +
          this.currentLanguage,
        null,
        (res) => {
          if (res.success) {
            this.content = res.data;
            //Non-repeating pages have only one content object
            if (this.content[0].Data == null) {
              this.content[0].Data = {};
            }

            if (this.content[0].DisplayLabel == null) {
              this.content[0].DisplayLabel = "";
            }
          } else {
            this.$store.dispatch("setError", res);
          }
        }
      );
    },
    getContent($state) {
      if (!this.contentIsLoading) {
        this.contentIsLoading = true;
        if (this.options.sortBy) {
          if (this.options.sortBy.length > 0) {
            this.tableSortBy = this.options.sortBy[0];
          }

          if (this.options.sortDesc.length > 0) {
            this.tableSortDescending = this.options.sortDesc[0];
            if (!this.options.sortDesc[0] && this.tableSortBy == "") {
              this.tableSortDescending =
                this.page.MostRecentItemsAtTheTop ?? false;
            }
          } else if (
            this.options.sortBy.length == 0 &&
            this.tableSortBy != ""
          ) {
            this.tableSortDescending = !this.tableSortDescending;
          } else if (
            this.options.sortDesc.length == 0 &&
            this.page.MostRecentItemsAtTheTop &&
            this.tableSortBy == ""
          ) {
            this.tableSortDescending =
              this.page.MostRecentItemsAtTheTop ?? false;
          }
        }

        var urlParameters =
          this.$route.params.id +
          "?start=" +
          (this.content == null ? 0 : this.content.length) +
          "&sortBy=" +
          this.tableSortBy +
          "&sortDescending=" +
          this.tableSortDescending +
          "&search=" +
          (this.searchValue == null ? "" : this.searchValue) +
          this.getPageFiltersQueryString();

        var getContentUrl =
          "/" +
          (this.page.Visualization == "table" ? "" : "minified") +
          "pagecontent/" +
          urlParameters;
        var getContentCountUrl = "/pagecontentcount/" + urlParameters;

        request.getAll([getContentCountUrl, getContentUrl], null, (res) => {
          if (res[0]) {
            if (res[0].success) {
              this.page.ContentCount = res[0].data;
              if (res[1].success) {
                if (this.content == null) {
                  this.content = res[1].data;
                } else if (this.content.length < this.page.ContentCount) {
                  this.content = this.content.concat(res[1].data);
                }

                this.$refs.infiniteLoading.stateChanger.loaded();

                if (this.content.length == this.page.ContentCount && $state) {
                  this.$refs.infiniteLoading.stateChanger.complete();
                }

                if (this.tableSortBy != "" && this.options.sortBy) {
                  this.options.sortDesc[0] = this.tableSortDescending;
                  this.options.sortBy[0] = this.tableSortBy;
                }

                this.contentIsLoading = false;
              } else {
                this.$store.dispatch("setError", res[1]);
                this.contentIsLoading = false;
              }
            } else {
              this.$store.dispatch("setError", res[0]);
              this.contentIsLoading = false;
            }
          } else {
            this.$store.dispatch("setError", res);
            this.contentIsLoading = false;
          }
        });
      }
    },
    removeItem(index) {
      this.$delete(this.content[0].Data, index);
    },
    addObjectToPageContent(properties) {
      var newObject = {
        Title: properties.objectTitle,
        Value: helpers.createEmptyValueBasedOnType(properties.objectType),
        Type: properties.objectType,
        Translate: properties.objectLanguages,
        Options: [],
        Properties: [],
        colWidth: 12,
      };
      if (
        properties.objectType == "text" ||
        properties.objectType == "richtext" ||
        properties.objectType == "datepicker" ||
        properties.objectType == "time" ||
        properties.objectType == "dropdown" ||
        properties.objectType == "content"
      ) {
        this.$set(newObject, "FieldValidation", true);
        this.$set(newObject, "FieldValidationFuntion", "notEmptyStringRule");
      }

      if (
        properties.objectType == "image" ||
        properties.objectType == "croppedimage" ||
        properties.objectType == "gallery"
      ) {
        newObject["AddAltTags"] = "true";
      }

      this.$set(this.content[0].Data, properties.objectName, newObject);
    },
    addObjectToContentDefinition(properties) {
      var newObject = {
        Title: properties.objectTitle,
        Value: helpers.createEmptyValueBasedOnType(properties.objectType),
        Type: properties.objectType,
        Translate: properties.objectLanguages,
        Options: [],
        Properties: [],
      };
      if (
        properties.objectType == "text" ||
        properties.objectType == "richtext" ||
        properties.objectType == "datepicker" ||
        properties.objectType == "time" ||
        properties.objectType == "dropdown" ||
        properties.objectType == "content"
      ) {
        this.$set(newObject, "FieldValidation", true);
        this.$set(newObject, "FieldValidationFuntion", "notEmptyStringRule");
      }

      if (
        properties.objectType == "image" ||
        properties.objectType == "croppedimage" ||
        properties.objectType == "gallery"
      ) {
        newObject["AddAltTags"] = "true";
      }

      this.$set(this.page.ContentDefinition, properties.objectName, newObject);
    },
    removeContentDefinitionItem(index) {
      this.$delete(this.page.ContentDefinition, index);
    },
    removeOption(index, optionIndex) {
      this.page.ContentDefinition[index].Options.splice(optionIndex, 1);
    },
    addOption(index) {
      this.addOptionFieldHasError = false;
      this.addOptionFieldRule = [];

      if (this.newOptionName.length > 1) {
        this.page.ContentDefinition[index].Options.push({
          Id: this.page.ContentDefinition[index].Options.length + 1,
          Value: this.newOptionName,
          Color: this.newOptionColor,
        });
      } else {
        this.addOptionFieldRule = [
          () => this.$i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addOptionFieldHasError = true;
      }
    },
    moveUpContentDefinition(index) {
      let keyOld;
      let keys = Object.keys(this.page.ContentDefinition);

      keys.forEach((element, i) => {
        if (element == index && i != 0) {
          keyOld = element;
          keys[i] = keys[i - 1];
          keys[i - 1] = keyOld;
        }
      });
      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.page.ContentDefinition[element];
      });
      this.page.ContentDefinition = newObject;
    },
    moveDownContentDefinition(index) {
      let keyOld;
      let keys = Object.keys(this.page.ContentDefinition);
      let isSet = false;
      keys.forEach((element, i) => {
        if (element == index && i !== keys.length - 1 && !isSet) {
          keyOld = element;
          keys[i] = keys[i + 1];
          keys[i + 1] = keyOld;
          isSet = true;
        }
      });

      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.page.ContentDefinition[element];
      });
      this.page.ContentDefinition = newObject;
    },
    moveUp(index) {
      var newSort;
      var oldSort = this.content[index].Sort;

      if (index == 0) {
        newSort = this.content[this.content.length - 1].Sort;
      } else {
        newSort = this.content[index - 1].Sort;
      }

      if (newSort == oldSort) {
        newSort += 1;
      }

      var indexToSwapWith = index - 1;

      if (index == 0) {
        indexToSwapWith = this.content.length - 1;
      }

      this.content[index].Sort = newSort;
      this.content[indexToSwapWith].Sort = oldSort;

      this.updateContentSort([
        this.content[index],
        this.content[indexToSwapWith],
      ]);
    },
    moveDown(index) {
      var newSort;
      var oldSort = this.content[index].Sort;

      if (index == this.content.length - 1) {
        newSort = this.content[0].Sort;
      } else {
        newSort = this.content[index + 1].Sort;
      }

      if (newSort == oldSort) {
        newSort -= 1;
      }
      var indexToSwapWith = index + 1;

      if (indexToSwapWith == this.content.length) {
        indexToSwapWith = 0;
      }

      this.content[index].Sort = newSort;
      this.content[indexToSwapWith].Sort = oldSort;

      this.updateContentSort([
        this.content[index],
        this.content[indexToSwapWith],
      ]);
    },
    updateContentSort(items) {
      request.put("/content/updatesort", items, (res) => {
        if (res.success) {
          this.content = this.content.sort(function (a, b) {
            return a.Sort - b.Sort;
          });
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
    exportPageContent() {
      this.exportDialog = false;

      var fieldsQueryString = "";
      for (var item in this.exportItems) {
        if (this.exportItems[item].selected) {
          fieldsQueryString += "fields=" + this.exportItems[item].value + "&";
        }
        for (var linkedItem in this.exportItems[item].linkedExportItems) {
          if (this.exportItems[item].linkedExportItems[linkedItem].selected) {
            fieldsQueryString +=
              "fields=" +
              this.exportItems[item].value +
              "." +
              this.exportItems[item].linkedExportItems[linkedItem].value +
              "&";
          }
        }
      }

      var queryString =
        "?sortBy=" +
        this.tableSortBy +
        "&sortDescending=" +
        this.tableSortDescending +
        "&search=" +
        (this.searchValue == null ? "" : this.searchValue) +
        this.getPageFiltersQueryString() +
        fieldsQueryString;

      request.download(
        "/exportcontent/" + this.page._id.$oid + queryString,
        { responseType: "blob" },
        "export_ " + this.page._id.$oid + ".xlsx"
      );
    },
    bulkUpdatePageContent() {
      this.bulkUpdatnigContent = true;

      var propertyName = "";
      for (var value in this.page.ContentDefinition) {
        if (
          this.page.ContentDefinition[value].Title ==
          this.selectedBulkUpdateProperty.Title
        ) {
          propertyName = value;
        }
      }

      var queryString =
        "?sortBy=" +
        this.tableSortBy +
        "&sortDescending=" +
        this.tableSortDescending +
        "&search=" +
        (this.searchValue == null ? "" : this.searchValue) +
        this.getPageFiltersQueryString() +
        "&propertyName=" +
        propertyName;

      request.put(
        "/bulkupdatecontent/" + this.page._id.$oid + queryString,
        this.selectedBulkUpdateProperty,
        (res) => {
          if (res.success) {
            if (
              this.page.SynchronizationBulkPutUrl &&
              this.page.SynchronizationBulkPutUrl.length > 0
            ) {
              request.put(
                this.page.SynchronizationBulkPutUrl +
                  "/" +
                  this.page._id.$oid +
                  queryString,
                this.selectedBulkUpdateProperty,
                (res) => {
                  if (!res.success) {
                    this.$store.dispatch("setError", res);
                  }
                }
              );
            }
            this.bulkUpdateDialog = false;
            this.bulkUpdatnigContent = false;
            this.$router.go(-1);
          } else {
            this.bulkUpdateDialog = false;
            this.bulkUpdatnigContent = false;
            this.uploadExcelError = res.message;
          }
        }
      );
    },
    getSharedPagesNotAssigned() {
      return this.selectedWebsite.Pages.filter(
        (p) => p.RepeatingContent === true
      );
    },
    customSort: function (items) {
      return items;
    },
    searchContent() {
      var scope = this;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.content = null;
        scope.$refs.infiniteLoading.stateChanger.reset();
        scope.getContent();

        clearTimeout(this.searchTimeout);
      }, 800);
    },
    onSearchCleared() {
      this.searchValue = "";
      this.content = null;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.getContent();
    },
    getBooleanValues() {
      return [
        { label: this.$i18n.translate("Yes"), value: true },
        { label: this.$i18n.translate("No"), value: false },
      ];
    },
    searchContentFilter(linkedPage, index) {
      if (this.searchingContentFilters[index]) return;
      if (!linkedPage || !auth.loggedIn()) return;

      this.searchingContentFilters[index] = true;

      request.get(
        "/pagecontentsearch/" +
          linkedPage.$oid +
          "?searchValue=" +
          this.searchContentFilterModels[index],
        null,
        (res) => {
          if (res.success) {
            if (!res.data.length) {
              this.$set(this.searchingContentFilters, index, false);
              return;
            }
            this.$set(this.contentFilterSearchResults, index, res.data);
            if (this.$refs["autocomplete"]) {
              this.$refs["autocomplete" + index][0].cachedItems = [];
            }
          } else {
            this.$store.dispatch("setError", res);
          }
          this.$set(this.searchingContentFilters, index, false);
        }
      );
    },
    filterChanged(value, filterOn) {
      this.pageFilters[filterOn] = value;
      this.content = null;
      this.getContent();
    },
    getPageFiltersQueryString() {
      var filters = "";
      for (var filter in this.pageFilters) {
        if (
          this.pageFilters[filter] != null &&
          this.pageFilters[filter] != undefined
        ) {
          if (Array.isArray(this.pageFilters[filter])) {
            for (var currentFilterIndex in this.pageFilters[filter]) {
              filters +=
                "filters=" +
                filter +
                ":" +
                this.pageFilters[filter][currentFilterIndex]._id.$oid +
                "&";
            }
          } else if (this.pageFilters[filter].$oid) {
            filters +=
              "filters=" + filter + ":" + this.pageFilters[filter].$oid + "&";
          } else {
            filters +=
              "filters=" + filter + ":" + this.pageFilters[filter] + "&";
          }
        }
      }
      return "&" + filters;
    },
    getExportItemsFromPage(page, isLinkedPageItem) {
      if (page.RepeatingContent) {
        var items = [];
        for (var value in page.ContentDefinition) {
          if (
            page.ContentDefinition[value].Type != "image" &&
            page.ContentDefinition[value].Type != "croppedimage" &&
            page.ContentDefinition[value].Type != "gallery" &&
            page.ContentDefinition[value].Type != "files" &&
            (page.ContentDefinition[value].Type != "content" ||
              !isLinkedPageItem)
          ) {
            items.push({
              title: page.ContentDefinition[value].Title,
              value: value,
              selected: false,
            });
          }
        }
        return items;
      }
      return [];
    },

    moveUpNonRepPage(index) {
      let keyOld;
      let keys = Object.keys(this.content[0].Data);

      keys.forEach((element, i) => {
        if (element == index && i != 0) {
          keyOld = element;
          keys[i] = keys[i - 1];
          keys[i - 1] = keyOld;
        }
      });
      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.content[0].Data[element];
      });
      this.content[0].Data = newObject;
    },
    moveDownNonRepPage(index) {
      let keyOld;
      let keys = Object.keys(this.content[0].Data);
      let isSet = false;
      keys.forEach((element, i) => {
        if (element == index && i !== keys.length - 1 && !isSet) {
          keyOld = element;
          keys[i] = keys[i + 1];
          keys[i + 1] = keyOld;
          isSet = true;
        }
      });

      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.content[0].Data[element];
      });
      this.content[0].Data = newObject;
    },
    deleteAll() {
      this.deleteAllContent = true;
      request.delete("/page-content/" + this.$route.params.id, null, (res) => {
        this.deleteAllContent = false;
        this.deleteAllDialog = false;
        if (res.success) {
          this.content = null;
        }
      });
    },
    getAmountCounter(count) {
      if (count < 1000) {
        return 100;
      } else if (count < 2000) {
        return 250;
      } else if (count < 5000) {
        return 500;
      } else {
        return 1000;
      }
    },
    setSyncDialog() {
      this.setSyncPageSnackbar = false;
      this.syncDialog = true;
      this.syncAllItems();
      this.setSyncPageSnackbar = false;
    },
    syncAllItems() {
      request.get(
        "/pagecontentcount/" + this.$route.params.id,
        null,
        (count) => {
          const apiCounterAmount = this.getAmountCounter(count.data);

          let processLength = Math.ceil(count.data / apiCounterAmount);
          this.toSyncItems.done = 0;
          this.toSyncItems.amount = count.data;
          this.toSyncItems.percentage = 0;
          this.processingSync = true;
          let masterdata = Object.assign(this.page.ContentDefinition);

          let requests = [];
          for (let i = 0; i < processLength; i++) {
            let itemsLength = i * apiCounterAmount;
            requests.push(
              "/pagecontent/" +
                this.$route.params.id +
                "?start=" +
                itemsLength +
                "&count=" +
                apiCounterAmount +
                "&sortDescending=false" +
                "&language=" +
                this.currentLanguage
            );
          }
          request.getAll(requests, null, (res) => {
            const resLenght = res.length;
            for (let i = 0; i < resLenght; i++) {
              let contentDefinition = [];
              res[i].data.forEach((element) => {
                let prototypeObj = Object.assign({});

                const keysToplvlItems = Object.keys(element);
                const dataKeys = Object.keys(element.Data);

                //loop through data of top levl items
                keysToplvlItems.forEach((keyOtherItems) => {
                  if (keyOtherItems == "Data") {
                    prototypeObj[keyOtherItems] = {};
                    const datakeysItems = Object.keys(masterdata);
                    datakeysItems.forEach((el) => {
                      if (!dataKeys.includes(el)) {
                        prototypeObj.Data[el] = masterdata[el];
                      } else {
                        prototypeObj.Data[el] = element.Data[el];
                      }
                    });
                  } else if (keyOtherItems == "DisplayLabel") {
                    prototypeObj[keyOtherItems] = element[keyOtherItems];
                  } else if (!prototypeObj[keyOtherItems]) {
                    prototypeObj[keyOtherItems] = element[keyOtherItems];
                  }
                });

                //loop through data of the Data object in the element
                dataKeys.forEach((dataKey) => {
                  let dummyObj = Object.assign({});
                  dummyObj[dataKey] = {};

                  //check if element inside both obj
                  if (
                    Object.prototype.hasOwnProperty.call(masterdata, dataKey)
                  ) {
                    const items = Object.keys(masterdata[dataKey]);
                    const itemsData = Object.keys(element.Data[dataKey]);
                    //loop through masterdatakeys to check difference
                    items.forEach((item) => {
                      const itemX = JSON.stringify(element.Data[dataKey][item]),
                        itemY = JSON.stringify(masterdata[dataKey][item]);
                      if (itemX !== itemY) {
                        if (item == "Value" || item == "ValueSort") {
                          //special case for files --> makes sure the data changes to een obj {Nl:}
                          if (
                            element.Data[dataKey].Type == "files" &&
                            masterdata[dataKey].TranslatedFiles &&
                            element.Data[dataKey][item]
                          ) {
                            if (Array.isArray(element.Data[dataKey][item])) {
                              dummyObj[dataKey][item] = element.Data[dataKey][
                                item
                              ]
                                ? { Nl: [...element.Data[dataKey][item]] }
                                : "";
                            } else {
                              dummyObj[dataKey][item] = element.Data[dataKey][
                                item
                              ]
                                ? element.Data[dataKey][item]["Nl"] ||
                                  element.Data[dataKey][item]["Fr"] ||
                                  element.Data[dataKey][item]["En"]
                                : "";
                            }
                          } else if (
                            element.Data[dataKey].Type == "files" &&
                            !masterdata[dataKey].TranslatedFiles &&
                            element.Data[dataKey][item]
                          ) {
                            dummyObj[dataKey][item] = element.Data[dataKey][
                              item
                            ]
                              ? element.Data[dataKey][item]["Nl"]
                              : "";
                          } else {
                            dummyObj[dataKey][item] = element.Data[dataKey][
                              item
                            ]
                              ? element.Data[dataKey][item]
                              : "";
                          }
                        } else {
                          dummyObj[dataKey][item] = masterdata[dataKey][item];
                        }
                      } else {
                        dummyObj[dataKey][item] = element.Data[dataKey][item];
                      }
                    });
                    itemsData.forEach((item) => {
                      if (
                        !Object.prototype.hasOwnProperty.call(
                          dummyObj[dataKey],
                          item
                        )
                      ) {
                        /* if (
                          item == "Value" ||
                          item == "ValueSort" 
                        ) { */
                        dummyObj[dataKey][item] = element.Data[dataKey][item]
                          ? element.Data[dataKey][item]
                          : "";
                        /* } */
                      }
                    });

                    prototypeObj.Data[dataKey] = dummyObj[dataKey];
                  }
                });

                contentDefinition.push(prototypeObj);
              });
              //put array items of call to request
              request.put("/contentlist", contentDefinition, (result) => {
                if (result.success) {
                  this.toSyncItems.done += res[i].data.length;
                  this.toSyncItems.percentage =
                    (this.toSyncItems.done / this.toSyncItems.amount) * 100;
                  if (this.toSyncItems.done == this.toSyncItems.amount) {
                    //getPageContent --> does refresh of data --> computed handels the visualization
                    this.getPage();
                    this.syncDialog = false;
                    this.processingSync = false;
                  }
                }
              });
            }
          });
        }
      );
    },
    getTableDropdown(item, needsColor = false) {
      if (!item.Value) {
        return "";
      }
      let result = item.Options.filter((obj) => {
        return obj.Id == item.Value;
      });
      if (result.length > 0) {
        if (needsColor) {
          return result[0].Color;
        }
        return result[0].Value;
      }
      return "";
    },
    getTableNumber(item) {
      if (item["IsPercentage"]) {
        return item.Value + " %";
      }
      return item.Value;
    },
    getTableDatepicker(item) {
      if (item.Value == "") {
        return "00-00-0000";
      }
      const [year, month, day] = item.Value.split("-");
      return day + "-" + month + "-" + year;
    },
    getTableText(item) {
      let dataValueLanguage = !item["Translate"] ? "Nl" : this.currentLanguage;
      if (item.Value[dataValueLanguage] == undefined) {
        return item.Value;
      } else {
        let text = item.Value[dataValueLanguage].replace(/<[^>]*>?/gm, "");
        if (text.length > 80) {
          return text.substr(0, 80) + "...";
        } else {
          return text;
        }
      }
    },
    getContentFromLinkedPage(item) {
      if (item.$oid) {
        request.get("/page/" + item.$oid, null, (res) => {
          if (res.success) {
            let arr = [];
            for (const key in res.data.ContentDefinition) {
              if (res.data.ContentDefinition[key].Type === "colorpicker") {
                const lang = res.data.ContentDefinition[key].TranslatedTitle[
                  this.currentLanguage
                ]
                  ? res.data.ContentDefinition[key].TranslatedTitle[
                      this.currentLanguage
                    ]
                  : res.data.ContentDefinition[key].TranslatedTitle["Nl"];
                arr.push({ id: key, value: lang });
              }
            }
            this.linkedPageContentItems = arr;
          } else {
            this.error = res.message;
          }
        });
      }
    },
    setDeletingItem(id, itemId, index) {
      this.$set(this.loadinItemToDelete, index, true);
      request.get("/content/" + itemId, null, (res) => {
        if (res.success) {
          this.itemToDelete = {
            displayLabel: res.data.DisplayLabel,
            id: id,
            itemId: itemId,
          };
          this.deleteItemDialog = true;
        } else {
          this.error = res.message;
        }
        this.$set(this.loadinItemToDelete, index, false);
      });
    },
    deletePageItem() {
      this.deleting = true;

      if (
        this.page.SynchronizationDeleteUrl &&
        this.page.SynchronizationDeleteUrl.length > 0
      ) {
        request.delete(
          this.page.SynchronizationDeleteUrl + "/" + this.itemToDelete.itemId,
          null,
          (res) => {
            if (!res.success) {
              this.error = res.message;
            }
            request.delete(
              "/content/" + this.itemToDelete.itemId,
              null,
              (res) => {
                this.deleting = false;

                if (res.success) {
                  const filterdContent = this.content.filter(
                    (el) => el._id.$oid !== this.itemToDelete.itemId
                  );
                  this.content = filterdContent;

                  this.deleteItemDialog = false;
                } else {
                  this.error = res.message;
                }
              }
            );
          }
        );
      } else {
        request.delete("/content/" + this.itemToDelete.itemId, null, (res) => {
          this.deleting = false;

          if (res.success) {
            const filterdContent = this.content.filter(
              (el) => el._id.$oid !== this.itemToDelete.itemId
            );
            this.content = filterdContent;

            this.deleteItemDialog = false;
          } else {
            this.error = res.message;
          }
        });
      }
    },
    showItem(item) {
      let returnValue = item;
      if (item["DisplayLabels"]) {
        returnValue = item.DisplayLabels[this.currentLanguage]
          ? item.DisplayLabels[this.currentLanguage]
          : item.DisplayLabels["Nl"];
      } else if (item["DisplayLabel"]) {
        returnValue = item.DisplayLabel;
      }
      return returnValue;
    },
  },
};
</script>

<style>
hr {
  border-top: 1px solid #bbb;
}

#editPageForm .textInput.error,
#editPageForm .editr--content.error {
  background-color: transparent !important;
}

#editPageForm .editr--content.error {
  border-width: 1px;
  border-style: solid;
}

.sort-button {
  margin-top: 5px;
}
.picker .v-input__control .v-input__slot .v-text-field__slot {
  height: 41.7px !important;
}
.picker .v-input__control .v-input__slot .v-text-field__slot input {
  margin-top: 0.85rem !important;
}
.property-filter {
  max-width: 200px;
  float: left;
  margin-right: 1rem;
}
.filter-height .v-select__slot {
  height: 32px;
}
/* .filters-row .v-select__slot {
  min-height: 42px;
} */
.max-width-layout {
  max-width: 30px;
}
.cursor-p {
  cursor: pointer;
}
@media only screen and (max-width: 568px) {
  .add-button {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .item-type {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 495px) {
  .sort-button {
    margin-top: -3px;
  }
}
</style>
