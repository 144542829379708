import request from "../request";
//initial state
const state = {
    error: null,
    isReporting: false,
    errorDialogViaError: false,
    errorDialog: false,
    fullError: null
}

//getters
const getters = {
    error: state => state.error,
    isReporting: state => state.isReporting,
    errorDialog: state => state.errorDialog,
    errorDialogViaError: state => state.errorDialogViaError,
    fullError: state => state.fullError
}

//actions
const actions = {
    setError({ commit }, error) {
        commit('setError', error)
    },
    setErrorMain({ commit }, error) {
        commit('setErrorMain', error)
    },
    setErrorDialog({ commit }, bool) {
        commit('setErrorDialog', bool)
    },
    errorDialogViaError({ commit }, bool) {
        commit('errorDialogViaError', bool)
    },
    resetError({ commit }) {
        commit('resetError')
    },
    reportErrorToDeveloper({ commit }, error) {
        commit('reportErrorToDeveloper', error)
    },
}

//mutations
const mutations = {
    setErrorDialog(state, bool) {
        state.errorDialog = bool
    },
    errorDialogViaError(state, bool) {
        state.errorDialogViaError = bool
    },
    setError(state, error) {
        state.fullError = error
        if (Object.prototype.hasOwnProperty.call(error, 'message')) {
            state.error = error.message
        }
        else {
            state.error = error
        }
    },
    setErrorMain(state, error) {
        state.fullError = error
        state.error = error.err
    },
    resetError(state) {
        state.fullError = null
        state.error = null
    },
    reportErrorToDeveloper(state, error) {
        state.isReporting = true
        const user = JSON.parse(localStorage.user)
        const errorObj = {
            UserEmail: user.Email,
            UserId: user._id,
            StackTrace: error['StackTrace'] ? error.StackTrace : error.errortext,
            PageId: error['PageId'] ? error.PageId : error['route'] ? error.route.params.id : "",
            ContentId: error['ContentId'] ? error.ContentId : ""
        }
        request.post('/reporterror', errorObj, () => {
            state.isReporting = false
            state.errorDialog = false
            state.errorDialogViaError = false
        })
    }

}

export default {
    state,
    getters,
    actions,
    mutations
}
