<template>
  <div>
    <backButton />
    <h3 class="mb-4">{{ $t("Settings") }}</h3>
    <v-row dense>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            {{ $t("Add light background logo") }}
          </v-card-title>
          <v-card-text>
            <dragAndDrop
              @setSnackbar="setSnackbar($event)"
              :multiple="false"
              uploadUrl="/settings/upload-logo-pos/"
              uploadBtnName="Upload logo"
              :needsUploadBtn="true"
              :accepts="['.svg', '.png']"
            />
            <errorHandeling
              v-if="config.logoPos"
              :buttons="[
                {
                  isText: true,
                  functionName: 'clearError',
                  text: 'Close',
                },
              ]"
              :snackbarText="$t('Not available due to old config file')"
              snackbarColor="warning"
              snackbarTimout="-1"
              snackbarIcon="mdi-alert-circle"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            {{ $t("Add dark background logo") }}
          </v-card-title>
          <v-card-text>
            <dragAndDrop
              @setSnackbar="setSnackbar($event)"
              :multiple="false"
              uploadUrl="/settings/upload-logo-neg"
              uploadBtnName="Upload logo"
              :needsUploadBtn="true"
              :accepts="['.svg', '.png']"
            />
            <errorHandeling
              v-if="config.logoNeg"
              :buttons="[
                {
                  isText: true,
                  functionName: 'clearError',
                  text: 'Close',
                },
              ]"
              :snackbarText="$t('Not available due to old config file')"
              snackbarColor="warning"
              snackbarTimout="-1"
              snackbarIcon="mdi-alert-circle"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            {{ $t("Add favicon") }}
          </v-card-title>
          <v-card-text>
            <dragAndDrop
              @setSnackbar="setSnackbar($event)"
              :multiple="false"
              uploadUrl="/settings/upload-favicon"
              uploadBtnName="Upload favicon"
              :needsUploadBtn="true"
              :accepts="['.ico']"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ $t("Onboarding") }}
          </v-card-title>
          <v-card-text>
            <v-row
              dense
              v-for="(onboardingItem, index) in onboardingItems"
              :key="index"
            >
              <v-col cols="12">
                <v-layout justify-end align-center>
                  <v-switch
                    v-model="isCustom[index]"
                    :label="$t('Is custom')"
                  ></v-switch>
                </v-layout>
              </v-col>
              <v-col cols="1">
                <v-layout column>
                  <v-btn text small icon color="grey" @click="moveUp(index)">
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>

                  <v-btn text small icon color="grey" @click="moveDown(index)">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-layout>
              </v-col>
              <onboarding-cards
                :onboardingItem="onboardingItem"
                :isCustom="isCustom[index]"
              />

              <v-col cols="1" class="d-flex justify-end align-center">
                <v-btn @click="deleteItem(index)" color="error" icon>
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="index + 1 !== onboardingItems.length">
                <v-divider class="my-2"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-layout justify-start>
                  <v-btn @click="addToOnboadingItems()" color="success">
                    {{ $t("Add onboarding") }}
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-end mt-4">
                <v-btn @click="saveOnboading" color="primary">
                  {{ $t("Save onboarding") }}
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <errorHandeling
      v-if="snackbar"
      :snackbarText="snackbarText"
      :buttons="[
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ]"
      :snackbarColor="snackbarColor"
      snackbarTimout="-1"
      snackbarIcon="mdi-alert-circle"
      @clearError="snackbar = false"
    />
  </div>
</template>

<script>
import DragAndDrop from "../shared/DragAndDrop.vue";
import request from "../../request";

export default {
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success",
      dragover: null,
      dragover2: null,
      dragover3: null,
      files: null,
      imagePos: {
        src: null,
        file: null,
      },
      imageNeg: {
        src: null,
        file: null,
      },
      favicon: {
        src: null,
        file: null,
      },
      config: this.$config,
      onboardingItems: [],
      onboardingCategory: null,
      textAreaOnboardingItem: [],
      isCustom: [],
    };
  },
  components: { DragAndDrop },

  created() {
    request.get("/onboardingsteps", null, (res) => {
      if (res.success) {
        this.onboardingItems = res.data;
        this.onboardingItems.forEach((element) => {
          for (const key in element.Components) {
            if (element.Components[key]) {
              this.isCustom[element.Sort] = true;
            }
          }
        });
      }
    });
  },
  computed: {
    disablePos() {
      if (this.$config.logoPos || !this.imagePos.src) {
        return true;
      }
      return false;
    },
    disableNeg() {
      if (this.$config.logoNeg || !this.imageNeg.src) {
        return true;
      }
      return false;
    },

    getFullOnboardingArray() {
      this.onboardingItems.forEach((element, index) => {
        this.onboardingItems[index]["Sort"] = index;
      });
      return this.onboardingItems;
    },
    disableFav() {
      if (!this.favicon.src) {
        return true;
      }
      return false;
    },
  },
  methods: {
    moveUp(index) {
      if (index == 0) return;

      const oldValue = this.onboardingItems[index];
      const newValue = this.onboardingItems[index - 1];
      this.onboardingItems.splice(index, 1, newValue);
      this.onboardingItems.splice(index - 1, 1, oldValue);
      this.$set(this.isCustom, index - 1, !this.isCustom[index - 1]);
      this.$set(this.isCustom, index, !this.isCustom[index]);
    },
    moveDown(index) {
      if (index == this.onboardingItems.length - 1) return;

      const oldValue = this.onboardingItems[index];
      const newValue = this.onboardingItems[index + 1];

      this.onboardingItems.splice(index, 1, newValue);
      this.onboardingItems.splice(index + 1, 1, oldValue);
      this.$set(this.isCustom, index + 1, !this.isCustom[index + 1]);
      this.$set(this.isCustom, index, !this.isCustom[index]);
    },
    addToOnboadingItems() {
      this.$set(this.onboardingItems, this.onboardingItems.length, {
        Components: {},
        Images: {},
        Texts: {},
      });

      window.scrollBy(0, window.innerHeight);
    },

    setSnackbar(e) {
      this.snackbar = true;
      this.snackbarColor = e.snackbarColor;
      this.snackbarText = e.snackbarText;
    },
    saveOnboading() {
      const items = this.getFullOnboardingArray;
      request.put("/onboardingsteps", items, (res) => {
        if (res.success) {
          this.snackbar = true;
          this.snackbarText = this.$t("Onboarding was successfully saved");
          this.snackbarColor = "success";
        }
      });
    },
    deleteItem(i) {
      this.$delete(this.isCustom, i);
      this.$delete(this.onboardingItems, i);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>