<template>
  <div>
    <template v-if="loading && !$store.getters.error">
      <preloader></preloader>
    </template>
    <template v-else>
      <backButton />

      <template v-if="page">
        <h2 class="mb-3">{{ page.Name[currentLanguage] }}</h2>

        <v-card class="mb-4">
          <v-card-title>
            {{ $t("Settings") }}
          </v-card-title>
          <v-card-text>
            <v-switch
              v-model="page.Enabled"
              :label="$t('Enabled')"
              light
              class=""
            >
            </v-switch>

            <v-switch
              v-model="page.EnableApi"
              :label="$t('Enable API')"
              light
              class=""
            >
            </v-switch>

            <v-switch
              v-model="page.Custom"
              :label="$t('Custom')"
              light
              class=""
            >
            </v-switch>

            <v-switch
              v-model="page.Group"
              :label="$t('Is grouppage')"
              light
              class=""
            >
            </v-switch>

            <v-switch
              v-model="page.RepeatingContent"
              :label="$t('Repeat content')"
              light
              class=""
            >
            </v-switch>

            <v-switch
              v-model="page.CacheContent"
              :label="$t('Cache content')"
              light
              class=""
            >
            </v-switch>

            <v-switch
              v-model="page.EnableOnSitemap"
              :label="$t('Enable on sitemap')"
              light
              class=""
            >
            </v-switch>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title>
            {{ $t("SEO") }}
          </v-card-title>
          <v-card-text>
            <h3 class="mb-3 mt-3">{{ $t("Page title") }}</h3>
            <v-row no-gutters>
              <v-col xs="12" sm="2" lg="1">
                <v-select
                  :items="pageLanguages"
                  v-model="pageDefaultLanguage"
                  single-line
                  class="pageElementLanguageSelect"
                ></v-select>
              </v-col>
              <v-col xs="12" sm="10" lg="11">
                <template
                  v-for="(translatedValue, translatedValueIndex) in page.Title"
                >
                  <template v-if="pageDefaultLanguage == translatedValueIndex">
                    <input
                      :key="translatedValueIndex"
                      class="page-detail textInput mt-2 mb-4"
                      v-model="page.Title[translatedValueIndex]"
                      type="text"
                    />
                  </template>
                </template>
              </v-col>
            </v-row>

            <h3 class="mb-3 mt-3">{{ $t("Page description") }}</h3>
            <v-row no-gutters>
              <v-col xs="12" sm="2" lg="1">
                <v-select
                  :items="pageDescriptions"
                  v-model="pageDefaultDescription"
                  single-line
                  class="pageElementLanguageSelect"
                ></v-select>
              </v-col>
              <v-col xs="12" sm="10" lg="11">
                <template
                  v-for="(
                    translatedValue, translatedValueIndex
                  ) in page.Description"
                >
                  <template
                    v-if="pageDefaultDescription == translatedValueIndex"
                  >
                    <textarea
                      :key="translatedValueIndex"
                      class="page-detail textInput mt-2"
                      v-model="page.Description[translatedValueIndex]"
                      rows="4"
                    />
                  </template>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-title>{{ $t("URL settings") }}</v-card-title>
          <v-card-text>
            <h3 class="mb-3 mt-3">Url</h3>
            <v-row no-gutters>
              <v-col xs="12" sm="2" lg="1">
                <v-select
                  :items="pageUrls"
                  v-model="pageDefaultUrl"
                  single-line
                  class="pageElementLanguageSelect"
                ></v-select>
              </v-col>
              <v-col xs="12" sm="10" lg="11">
                <template
                  v-for="(translatedValue, translatedValueIndex) in page.Title"
                >
                  <template v-if="pageDefaultUrl == translatedValueIndex">
                    <input
                      :key="translatedValueIndex"
                      class="page-detail textInput mt-2"
                      v-model="page.Url[translatedValueIndex]"
                      type="text"
                    />
                  </template>
                </template>
              </v-col>
            </v-row>

            <h3 class="mb-3 mt-3">{{ $t("Unique name") }}</h3>
            <v-row no-gutters>
              <v-col xs="12" sm="4">
                <input
                  class="page-detail textInput mb-4"
                  v-model="page.UniqueName"
                  type="text"
                />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>

            <h3 class="mb-3 mt-3">{{ $t("Synchronization URLs") }}</h3>
            <v-row no-gutters class="mb-4">
              <v-col class="px-0" cols="8">
                <input
                  class="page-detail textInput"
                  v-model="page.SynchronizationPutUrl"
                  placeholder="Put URL"
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-4" v-if="page.RepeatingContent">
              <v-col cols="8">
                <input
                  class="page-detail textInput"
                  v-model="page.SynchronizationBulkPutUrl"
                  placeholder="Bulk put URL"
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row no-gutters v-if="page.RepeatingContent">
              <v-col cols="8">
                <input
                  class="page-detail textInput mb-4"
                  v-model="page.SynchronizationDeleteUrl"
                  placeholder="Delete URL"
                  type="text"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-title>{{ $t("Icon") }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col xs="12" sm="4" lg="4">
                <v-autocomplete
                  v-if="allIcons"
                  v-model="page.Icon"
                  :loading="loading"
                  :items="allIcons"
                  class="mx-4"
                  flat
                  item-value="icon"
                  item-text="name"
                  :append-outer-icon="page.Icon"
                  :label="$t('Select an icon')"
                >
                  <template v-slot:item="{ item }">
                    <v-icon>
                      {{ item.icon }}
                    </v-icon>
                    <span class="ml-2">
                      {{ item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-title>{{ $t("Security") }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col xs="12" sm="4" lg="4">
                <v-switch
                  v-model="page.AllUsersHaveAccess"
                  :label="$t('All users have access')"
                  light
                  class=""
                  @change="!page.AllUsersHaveAccess ? getUsers() : ''"
                >
                </v-switch>
                <template
                  v-if="!page.AllUsersHaveAccess"
                  elevation="0"
                  color="mb-8"
                >
                  <h3 class="mb-3 mt-3">{{ $t("Users with access") }}</h3>
                  <v-autocomplete
                    clearable
                    v-model="page.UsersWithAccess"
                    multiple
                    chips
                    item-text="Email"
                    item-value="_id"
                    hide-details="true"
                    :items="users"
                  >
                  </v-autocomplete>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-title>
            {{ $t("Linked Pages") }}
          </v-card-title>
          <v-card-text>
            <h3 v-if="getPagesNotAssigned().length > 0" class="mb-3 mt-3">
              {{ $t("Subpages") }}
            </h3>
            <template v-for="(subPage, index) in page.SubPages">
              <v-row no-gutters :key="index">
                <v-col xs="9" sm="9" lg="11">
                  <h4 class="mt-3 page-label">
                    {{ getPageNameFromId(subPage.$oid) }}
                  </h4>
                </v-col>
                <v-col xs="3" sm="3" lg="1">
                  <v-btn icon color="red" v-on:click="removeSubPage(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-row>
                  <v-select
                    v-if="getPagesNotAssigned().length > 0"
                    v-bind:items="getPagesNotAssigned()"
                    :label="$t('Select a page')"
                    item-value="_id.$oid"
                    item-text="NameCurrentLanguage"
                    v-model="pageToAdd"
                    single-line
                    class="add-item-select mr-3"
                  ></v-select>
                  <v-btn
                    color="green"
                    class="
                      mt-3
                      mb-3
                      ml-0
                      edit-page-action-button
                      dropdown-action-button
                    "
                    dark
                    v-on:click="addSubPage()"
                    v-if="pageToAdd && getPagesNotAssigned().length > 0"
                  >
                    {{ $t("Add page") }}
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="page.RepeatingContent">
              <v-col xs="12" sm="12" lg="12">
                <h3 class="mt-3">{{ $t("Shared pages") }}</h3>
              </v-col>
              <v-col xs="12" sm="10" lg="11">
                <template v-for="(linkedPage, index) in page.LinkedPages">
                  <v-row :key="index">
                    <v-col xs="9" sm="9" lg="11">
                      <h4 class="mt-3 page-label">
                        {{ getWebsitePageLabelFromPageId(linkedPage.$oid) }}
                      </h4>
                    </v-col>
                    <v-col xs="3" sm="3" lg="1">
                      <v-btn
                        icon
                        color="red"
                        v-on:click="removeLinkedPage(index)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
              <v-col xs="12" sm="10" lg="11">
                <v-select
                  v-if="otherWebsites"
                  v-bind:items="otherWebsites"
                  v-on:change="getPagesForSelectedWebsite"
                  :label="$t('Select a module')"
                  item-text="Name"
                  v-model="selectedLinkedWebsite"
                  single-line
                  class="add-item-select mr-3"
                  item-value="_id"
                ></v-select>

                <v-select
                  v-if="getSharedPagesNotAssigned().length > 0"
                  v-bind:items="getSharedPagesNotAssigned()"
                  :label="$t('Select a page')"
                  v-model="selectedLinkedPage"
                  single-line
                  item-text="Name"
                  item-value="_id"
                  class="add-item-select mr-3"
                >
                  <template slot="selection" slot-scope="data">
                    <div class="input-group__selections__comma">
                      {{ data.item.Name[currentLanguage] }}
                    </div>
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.Name[currentLanguage] }}
                  </template>
                </v-select>

                <v-btn
                  color="green"
                  class="
                    mt-3
                    mb-3
                    ml-0
                    edit-page-action-button
                    dropdown-action-button
                  "
                  dark
                  v-on:click="addLinkedPage()"
                  v-if="
                    selectedLinkedPage && getSharedPagesNotAssigned().length > 0
                  "
                >
                  {{ $t("Add page") }}
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <cancelConfirmButtons
        @goBack="goBack"
        :confirmFunction="savePage"
        :loading="saving"
        :confirmLabel="$t('Save')"
      />
    </template>
  </div>
</template>

<script>
import request from "../../request";
import helpers from "../../helpers";
import json from "../../locale/Icons";

export default {
  data() {
    return {
      saving: false,
      page: null,
      pageLanguages: null,
      pageDefaultLanguage: null,
      pageDescriptions: null,
      pageDefaultDescription: null,
      pageUrls: null,
      pageDefaultUrl: null,
      pages: null,
      pageToAdd: null,
      loading: false,
      websites: null,
      otherWebsites: null,
      selectedLinkedWebsite: null,
      selectedLinkedWebsitePages: null,
      selectedLinkedPage: null,
      allIcons: json.icons(),
      icon: "",
    };
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
  },
  created() {
    this.getWebsites();
    this.getUsers();

    if (!this.$route.params.id > 0) {
      let newPage = {
        Name: helpers.createEmptyValueBasedOnType("text"),
        RepeatingContent: false,
        Enabled: false,
        EnableApi: false,
        Custom: false,
        CacheContent: false,
        WebsiteId: this.$route.params.websiteId,
        Title: helpers.createEmptyValueBasedOnType("text"),
        Description: helpers.createEmptyValueBasedOnType("text"),
        Url: helpers.createEmptyValueBasedOnType("text"),
        UniqueName: "",
        SubPages: [],
        LinkedPages: [],
        Icon: "",
        Group: false,
      };

      newPage.Name[this.currentLanguage] = this.$route.params.name;

      this.pageLanguages = Object.keys(newPage.Title);
      this.pageDefaultLanguage = Object.keys(newPage.Title)[0];
      this.pageDescriptions = Object.keys(newPage.Description);
      this.pageDefaultDescription = Object.keys(newPage.Description)[0];
      this.pageUrls = Object.keys(newPage.Url);
      this.pageDefaultUrl = Object.keys(newPage.Url)[0];

      this.page = newPage;
      this.getPages(this.$route.params.websiteId.$oid);
    } else {
      this.getPage();
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getUsers() {
      request.get("/users", null, (res) => {
        if (res.success) {
          this.users = res.data;
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
    getPage() {
      this.loading = true;
      this.page == null;

      request.get("/page/" + this.$route.params.id, null, (res) => {
        if (res.success) {
          if (res.data.Title == null) {
            res.data.Title = helpers.createEmptyValueBasedOnType("text");
          }

          if (res.data.Description == null) {
            res.data.Description = helpers.createEmptyValueBasedOnType("text");
          }

          if (res.data.Url == null) {
            res.data.Url = helpers.createEmptyValueBasedOnType("text");
          }

          if (res.data.SynchronizationPutUrl == null) {
            res.data.SynchronizationPutUrl = "";
          }

          if (res.data.SynchronizationBulkPutUrl == null) {
            res.data.SynchronizationBulkPutUrl = "";
          }

          if (res.data.SynchronizationDeleteUrl == null) {
            res.data.SynchronizationDeleteUrl = "";
          }

          this.pageLanguages = Object.keys(res.data.Title);
          this.pageDefaultLanguage = Object.keys(res.data.Title)[0];
          this.pageDescriptions = Object.keys(res.data.Description);
          this.pageDefaultDescription = Object.keys(res.data.Description)[0];
          this.pageUrls = Object.keys(res.data.Url);
          this.pageDefaultUrl = Object.keys(res.data.Url)[0];

          if (res.data.LinkedPages == undefined) {
            res.data.LinkedPages = [];
          }
          this.page = res.data;
          this.$store.dispatch("setSelectedWebsiteById", this.page.WebsiteId);
          this.getPages(this.page.WebsiteId.$oid);
          this.setOtherWebsites();
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
    savePage() {
      this.saving = true;
      request.put("/page", this.page, (res) => {
        this.saving = false;

        if (res.success) {
          this.getWebsites();
          this.$router.go(-1);
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
    getPages(websiteId) {
      if (websiteId) {
        this.loading = true;

        request.get("/website/" + websiteId, null, (res) => {
          this.loading = false;

          if (res.success) {
            this.pages = res.data.Pages.sort(
              helpers.sortByKey(this.currentLanguage)
            );
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      }
    },
    getWebsites() {
      request.get("/websites", null, (res) => {
        if (res.success) {
          this.$store.dispatch("setAllWebsites", res.data);
          this.websites = res.data;
          this.setOtherWebsites();
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
    getPagesNotAssigned() {
      var pages = [];

      if (this.pages && this.page) {
        for (var i = 0; i < this.pages.length; i++) {
          var subpageAssignedToPage = false;
          for (var ii = 0; ii < this.page.SubPages.length; ii++) {
            if (this.pages[i]._id.$oid == this.page.SubPages[ii].$oid) {
              subpageAssignedToPage = true;
            }
          }

          var currentPageId = "";
          if (this.page._id != undefined) {
            currentPageId = this.page._id.$oid;
          }

          if (
            !subpageAssignedToPage &&
            this.pages[i]._id.$oid != currentPageId
          ) {
            this.pages[i].NameCurrentLanguage =
              this.pages[i].Name[this.currentLanguage];
            pages[pages.length] = this.pages[i];
          }
        }
      }

      return pages;
    },
    getSharedPagesNotAssigned() {
      var pages = [];
      if (this.selectedLinkedWebsite && this.page) {
        for (var i = 0; i < this.selectedLinkedWebsitePages.length; i++) {
          var linkedPageAssignedToPage = false;
          for (var ii = 0; ii < this.page.LinkedPages.length; ii++) {
            if (
              this.selectedLinkedWebsitePages[i]._id.$oid ==
              this.page.LinkedPages[ii].$oid
            ) {
              linkedPageAssignedToPage = true;
            }
          }

          var currentPageId = "";
          if (this.page._id != undefined) {
            currentPageId = this.page._id.$oid;
          }

          if (
            !linkedPageAssignedToPage &&
            this.selectedLinkedWebsitePages[i]._id.$oid != currentPageId
          ) {
            this.selectedLinkedWebsitePages[i].NameCurrentLanguage =
              this.selectedLinkedWebsitePages[i].Name[this.currentLanguage];
            pages[pages.length] = this.selectedLinkedWebsitePages[i];
          }
        }
      }
      return pages;
    },
    getPageNameByCurrentLanguage(names) {
      return names[this.currentLanguage];
    },
    addSubPage() {
      if (this.pageToAdd) {
        this.page.SubPages.push({
          $oid: this.pageToAdd,
        });
      }
    },
    getPageNameFromId(id) {
      if (this.pages) {
        for (var i = 0; i < this.pages.length; i++) {
          if (this.pages[i]._id.$oid == id) {
            return this.pages[i].Name[this.currentLanguage];
          }
        }
      }
      return "";
    },
    getWebsitePageLabelFromPageId(id) {
      if (this.websites) {
        for (var i = 0; i < this.websites.length; i++) {
          for (var ii = 0; ii < this.websites[i].Pages.length; ii++) {
            if (this.websites[i].Pages[ii]._id.$oid == id) {
              return (
                this.websites[i].Name +
                " - " +
                this.websites[i].Pages[ii].Name[this.currentLanguage]
              );
            }
          }
        }
      }

      return "";
    },
    removeSubPage(index) {
      this.page.SubPages.splice(index, 1);
    },
    setOtherWebsites() {
      if (this.page && this.websites) {
        this.otherWebsites = this.websites.filter(
          (x) => x._id.$oid != this.page.WebsiteId.$oid
        );
      }
    },
    getPagesForSelectedWebsite(id) {
      this.selectedLinkedWebsitePages = this.websites
        .find((w) => w._id.$oid == id.$oid)
        .Pages.filter((p) => p.RepeatingContent);
    },
    addLinkedPage() {
      if (this.selectedLinkedPage) {
        this.page.LinkedPages.push({
          $oid: this.selectedLinkedPage.$oid,
        });
      }
    },
    removeLinkedPage(index) {
      this.page.LinkedPages.splice(index, 1);
    },
    changeIcon(newIcon) {
      if (newIcon.icon !== null && newIcon.icon !== undefined) {
        this.page.Icon = newIcon.icon;
        return;
      }
      this.page.Icon = "";
    },
  },
};
</script>

<style>
.page-label {
  color: #666;
}
</style>
