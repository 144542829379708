<template>
  <div>
    <v-icon id="back-button" class="mb-4" v-on:click="goBack"
      >mdi-arrow-left</v-icon
    >
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
#back-button {
  cursor: pointer;
  margin-left: -3px;
}
</style>
