<template>
  <div class="mt-2">
    <template v-if="websites.length > 0">
      <addButton
        toRoute="websitesoverview/createeditwebsite/"
        :label="$t('Add module')"
      />

      <v-card>
        <v-layout
          custom-overview-row
          row
          v-for="(website, index) in websites"
          :key="website.Name"
        >
          <v-flex
            custom-overview-row-title
            lg10
            sm9
            xs6
            pt-3
            pl-3
            pb-3
            subheading
            :key="index"
          >
            {{ website.Name }}
          </v-flex>
          <v-flex custom-overview-row-actions lg2 sm3 xs6 text-xs-right>
            <router-link
              :to="{
                name: 'createEditWebsite',
                params: { id: website._id.$oid },
              }"
            >
              <v-btn class="mr-1" icon color="orange">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </router-link>
            <router-link
              :to="{ name: 'deleteWebsite', params: { id: website._id.$oid } }"
            >
              <v-btn class="mr-1" icon color="red">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </router-link>
          </v-flex>
        </v-layout>
      </v-card>
    </template>
    <template v-else-if="!$store.getters.error">
      <preloader></preloader>
    </template>
  </div>
</template>

<script>
import request from "../../request";
import { mapGetters } from "vuex";

export default {
  created() {
    this.getWebsites();
  },
  computed: mapGetters({
    websites: "allWebsites",
  }),
  methods: {
    getWebsites() {
      request.get("/websites", null, (res) => {
        if (res.success) {
          this.$store.dispatch("setAllWebsites", res.data);
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },
  },
};
</script>
