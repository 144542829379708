var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h3',{staticClass:"display-3 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("Welcome"))+", "+_vm._s(_vm.user.FirstName)+" ")]),(_vm.userIsAdministrator)?_c('span',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t("Using this application administrator"))+" ")]):_c('span',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t("Using this application"))+" ")]),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"dashboardTitle mb-3"},[_c('div',{staticClass:"dashboardIconContainer"},[_c('v-icon',{staticClass:"mt-2"},[_vm._v("mdi-account-circle")])],1),_c('div',{staticClass:"dashboardActionContainer"},[_c('a',{attrs:{"href":'mailto:' +
              _vm.mailto +
              '?Subject=' +
              _vm.$t('Request user access') +
              '&Body=' +
              _vm.$t('Request user access body')}},[_vm._v(_vm._s(_vm.$t("Request user access?")))])])]),_c('div',{staticClass:"dashboardTitle mb-3"},[_c('div',{staticClass:"dashboardIconContainer"},[_c('v-icon',[_vm._v("mdi-message-plus")])],1),_c('div',{staticClass:"dashboardActionContainer"},[_c('a',{attrs:{"href":'mailto:' +
              _vm.mailto +
              '?Subject=' +
              _vm.$t('Request a new feature') +
              '&Body=' +
              _vm.$t('Request a new feature body')}},[_vm._v(_vm._s(_vm.$t("Request a new feature?")))])])]),_c('div',{staticClass:"dashboardTitle mb-3"},[_c('div',{staticClass:"dashboardIconContainer"},[_c('v-icon',[_vm._v("mdi-alert")])],1),_c('div',{staticClass:"dashboardActionContainer"},[_c('a',{attrs:{"href":'mailto:' +
              _vm.mailto +
              '?Subject=' +
              _vm.$t('Having trouble') +
              '&Body=' +
              _vm.$t('Having trouble body')}},[_vm._v(_vm._s(_vm.$t("Having trouble?")))])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }